package ru.novasoft.roads.compose_client.core.ui.dialog

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import ru.novasoft.roads.compose_client.core.designsystem.theme.menu

@Composable
fun CommonDialog(
    title: String,
    onOk: () -> Unit,
    onDismissRequest: () -> Unit = {},
    okBtnText: String = "Ок",
    cancelBtnText: String = "Отмена",
    isValidState: Boolean = true,
    content: @Composable () -> Unit
) {
    Dialog(onDismissRequest = { onDismissRequest() }) {
        Card(
            modifier = Modifier
                .fillMaxWidth()
                .padding(10.dp),
            shape = RoundedCornerShape(5.dp),
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(20.dp),
                verticalArrangement = Arrangement.spacedBy(10.dp)
            ) {
                Text(
                    text = title,
                    modifier = Modifier.padding(start = 15.dp),
                    color = MaterialTheme.colorScheme.onSurfaceVariant,
                    style = MaterialTheme.typography.headlineSmall,
                )

                HorizontalDivider(modifier = Modifier.height(20.dp))

                content()

                Row(
                    modifier = Modifier
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.spacedBy(10.dp, Alignment.End),
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    TextButton(
                        onClick = { onDismissRequest() },
                        modifier = Modifier.width(120.dp),
                        colors = ButtonDefaults.textButtonColors()
                            .copy(contentColor = MaterialTheme.colorScheme.secondaryContainer)
                    ) {
                        Text(
                            text = cancelBtnText,
                            style = MaterialTheme.typography.menu,
                        )
                    }
                    TextButton(
                        onClick = onOk,
                        modifier = Modifier.width(120.dp),
                        colors = ButtonDefaults.textButtonColors().copy(
                            containerColor = MaterialTheme.colorScheme.secondaryContainer,
                            contentColor = MaterialTheme.colorScheme.onSecondaryContainer
                        ),
                        enabled = isValidState
                    ) {
                        Text(
                            text = okBtnText,
                            style = MaterialTheme.typography.menu,
                        )
                    }
                }

            }
        }
    }
}

