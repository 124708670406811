package src.novasoft.roads.compose_client.feature.menu.contracts

import common.RoadsAppEvent
import common.RoadsUiEvent
import novasoft.roads.dto.client_common.ContractDto
import novasoft.roads.dto.client_common.FolderDto
import novasoft.roads.dto.meter_progress.ProgressStatus

data class ContractCardParams(
    val progress: Double = 0.0,
    val progressStatus: ProgressStatus = ProgressStatus.PLAN
)

data class ContractsState(
    val contracts: List<ContractDto> = listOf(),
    val contractCardParams: Map<Int, ContractCardParams> = mapOf(),
    val folders: List<FolderDto> = listOf(),
)

sealed class ContractsUiEvent : RoadsUiEvent {
    data class FolderClicked(val folderDto: FolderDto) : ContractsUiEvent()
    data class ContractClicked(val contractDto: ContractDto) : ContractsUiEvent()
    data class SomethingDroppedOnFolder(val data: ContractsDragAndDropData, val targetFolder: FolderDto) :
        ContractsUiEvent()

    /** Всплывающий список действий на карточках контрактов или папок*/
    sealed class CardBaseAction(val actionName: String) : ContractsUiEvent() {

        /** Всплывающий список действий на карточках контрактов*/
        sealed class ContractCardAction(val contractDto: ContractDto, actionName: String) : CardBaseAction(actionName) {
            class MoveToUpFolder(contractDto: ContractDto, actionName: String = "На уровень выше") :
                ContractCardAction(contractDto, actionName)
            class EditContractClicked(contractDto: ContractDto, actionName: String = "Изменить") :
                ContractCardAction(contractDto, actionName)
            class CopyContract(contractDto: ContractDto, actionName: String = "Скопировать") :
                ContractCardAction(contractDto, actionName)
            class DeleteContract(contractDto: ContractDto, actionName: String = "Удалить") :
                ContractCardAction(contractDto, actionName)
        }

        /** Всплывающий список действий на карточках папок*/
        sealed class FolderCardAction(val folderDto: FolderDto, actionName: String) : CardBaseAction(actionName) {
            class MoveToUpFolder(folderDto: FolderDto, actionName: String = "На уровень выше") :
                FolderCardAction(folderDto, actionName)
            class RenameFolderClicked(folderDto: FolderDto, actionName: String = "Переименовать") :
                FolderCardAction(folderDto, actionName)
            class DeleteFolder(folderDto: FolderDto, actionName: String = "Удалить") :
                FolderCardAction(folderDto, actionName)
        }
    }
    data class RenameFolder(val folderID: Long, val newName: String) : ContractsUiEvent()
    data class EditContract(val newContractDto: ContractDto) : ContractsUiEvent()
}

sealed class ContractsAppEvent : RoadsAppEvent {
    data class OpenedContractState(val openedContract: ContractDto? = null) : ContractsAppEvent()
    data class RenameFolder(val renamingFolder: FolderDto): ContractsAppEvent()
    data class EditContract(val editingContract: ContractDto) : ContractsAppEvent()
}
