package ru.novasoft.roads.compose_client.core.network.api.auth

import novasoft.roads.dto.client_common.LoginRequestDto
import novasoft.roads.dto.company_team.person.PersonInfoDto
import ru.novasoft.roads.compose_client.core.model.dto.Response
import ru.novasoft.roads.compose_client.core.network.client.ClientMapper
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient

class AuthApi(override val client: RoadsClient) : ClientMapper(client, "auth"), IAuthApi {
    override suspend fun signIn(loginRequest: LoginRequestDto): Response<PersonInfoDto> =
        httpSendAndGetJson<LoginRequestDto, PersonInfoDto>("login_with_hash_for_person_info", loginRequest)

    override suspend fun signUp() = httpSendJsonGetByteArray<String>("logout", null, 2)
}