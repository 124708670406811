package ru.novasoft.roads.compose_client.core.ui.menu

import androidx.compose.runtime.Composable
import common.RoadsUiEvent

sealed interface PageHeaderEvent: RoadsUiEvent {
    data object BackClicked: PageHeaderEvent
    data object HelpClicked: PageHeaderEvent
}

interface PageHeaderContract {
    fun getTabs(): Map<String, () -> Unit> = emptyMap()
    fun onPageHeaderEvent(event: PageHeaderEvent)
}