package novasoft.roads.dto.client_common

import kotlinx.serialization.Serializable

@Serializable
data class ContractDto(
    val name: String = "",
    val customerName: String = "",
    val contractNumber: String = "",
    val type: ContractType = ContractType.NONE,
    val startDate: Long = 0L,
    val archive: Boolean = false,
    val contractId: Int = -1,
    val worth: Double = 0.0,
    val range: RoadRangeDto<Float>? = null,
    // Если у контракта несколько складов, то их названия должны быть разделены символом ";"
    val storagesFrom1C: String = ""
)