package ru.novasoft.roads.compose_client.core.ui

import androidx.compose.ui.unit.dp

object DefaultSizes {
    object Padding {
        val defaultPadding = 30.0.dp
        val defaultSpacing = 10.0.dp
        val menuStartPadding = 14.0.dp
    }
}