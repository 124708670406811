import androidx.compose.foundation.layout.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.compose.rememberNavController
import org.kodein.di.compose.withDI
import ru.novasoft.roads.compose_client.core.data.di.sharedDataDI
import ru.novasoft.roads.compose_client.core.designsystem.theme.RoadsTheme
import ru.novasoft.roads.compose_client.core.model.roating.RootRoatings
import src.novasoft.roads.compose_client.feature.auth.AuthView

/** Общая точка входа для других платформ. Прим.: не для Preview */
@Composable
fun RootViewTopLevel() {
    val rootNavController: NavHostController = rememberNavController()

    RoadsTheme {
        RootView(
            rootNavController = rootNavController
        )
    }
}

/** Функция во многом для реализации Preview */
@Composable
fun RootView(
    content: (@Composable () -> Unit)? = null,
    rootNavController: NavHostController
) {
    withDI(sharedDataDI()) {
        Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
            /**
             * Хост навигации. Очень похоже на контроллеры в spring, где в composable прописывается
             * путь-указатель на страницу.
             *
             * Чтобы уменьшить число хардкода для роутинга выбран enum.
             *
             * В каждое View прокидываем navController, чтобы из них производить дальнейшее перемещение.
             * В AuthView просто достаточно действия на успешный auth.
             */
            content?.invoke() ?: NavHost(
                navController = rootNavController,
                startDestination = RootRoatings.Auth.name,
                modifier = Modifier.fillMaxSize()
            ) {
                composable(RootRoatings.Auth.name) { AuthView(onSuccessAuth = { rootNavController.navigate(RootRoatings.MainApp.name) }) }
                composable(RootRoatings.MainApp.name) { RoadsSPAView(rootNavController) }
            }
        }
    }
}