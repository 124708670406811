package novasoft.roads.dto.entities_bundle

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MeasuringUnitDto(
    @SerialName("i")
    val id: Int,
    @SerialName("a")
    val abbreviation: String,
    @SerialName("su")
    val standardUnitId: Int,
    @SerialName("s")
    val scale: Float
)