package ru.novasoft.roads.compose_client.core.data.remote.impl

import novasoft.roads.dto.client_common.LoginRequestDto
import org.kotlincrypto.hash.sha2.SHA256
import ru.novasoft.roads.compose_client.core.data.remote.IAuthRepository
import ru.novasoft.roads.compose_client.core.network.api.auth.IAuthApi

class AuthRepository(private val authApi: IAuthApi) : IAuthRepository {
    override suspend fun signIn(
        login: String,
        password: String
    ) = authApi.signIn(LoginRequestDto(login, hashPassword(password)))

    @OptIn(ExperimentalStdlibApi::class)
    private fun hashPassword(pass: String): String {
        return SHA256().digest(pass.encodeToByteArray()).toHexString()
    }

    override suspend fun signUp() = authApi.signUp()
}
