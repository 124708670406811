package ru.novasoft.roads.compose_client.core.ui.effects

import androidx.compose.animation.core.*
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Brush

@Composable
fun LoadingEffect(
    modifier: Modifier = Modifier.fillMaxSize(),
    widthOfShadowBrush: Int = 500,
    angleOfAxisY: Float = 250f,
    durationMillis: Int = 1000,
) {
    val shimmerColors = listOf(
        MaterialTheme.colorScheme.onBackground.copy(alpha = 0.3f),
        MaterialTheme.colorScheme.onBackground.copy(alpha = 0.5f),
        MaterialTheme.colorScheme.onBackground.copy(alpha = 1.0f),
        MaterialTheme.colorScheme.onBackground.copy(alpha = 0.5f),
        MaterialTheme.colorScheme.onBackground.copy(alpha = 0.3f),
    )

    val transition = rememberInfiniteTransition()
    val animation = transition.animateFloat(
        initialValue = 0f,
        targetValue = (durationMillis + widthOfShadowBrush).toFloat(),
        animationSpec = infiniteRepeatable(
            animation = tween(
                durationMillis = durationMillis,
                easing = LinearEasing,
            ),
            repeatMode = RepeatMode.Restart,
        )
    )
    val brush = Brush.linearGradient(
        colors = shimmerColors,
        start = Offset(x = animation.value - widthOfShadowBrush, y = 0.0f),
        end = Offset(x = animation.value, y = angleOfAxisY),
    )

    Box(
        modifier = modifier
    ) {
        Spacer(
            modifier = Modifier
                .matchParentSize()
                .background(brush)
        )
    }
}