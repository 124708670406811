package src.novasoft.roads.compose_client.feature.menu.contracts.folderCard

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.scale
import com.mohamedrejeb.compose.dnd.DragAndDropState
import com.mohamedrejeb.compose.dnd.drag.DraggableItem
import com.mohamedrejeb.compose.dnd.drop.dropTarget
import novasoft.roads.dto.client_common.FolderDto
import src.novasoft.roads.compose_client.feature.menu.contracts.CardBase
import src.novasoft.roads.compose_client.feature.menu.contracts.ContractsDragAndDropData
import src.novasoft.roads.compose_client.feature.menu.contracts.ContractsUiEvent


@Composable
fun FolderCardVIew(
    folderDto: FolderDto,
    dragAndDropState: DragAndDropState<ContractsDragAndDropData>,
    onClick: () -> Unit = {},
    onAction: (ContractsUiEvent.CardBaseAction) -> Unit = {},
    actions: List<ContractsUiEvent.CardBaseAction.FolderCardAction> = emptyList(),
    onDrop: (data: ContractsDragAndDropData, targetFolder: FolderDto) -> Unit,
) {
    makeDraggableIfNeed(folderDto, dragAndDropState) {
        CardBase(
            title = folderDto.name,
            modifier = Modifier.dropTarget(
                state = dragAndDropState,
                key = folderDto.id, // Unique key for each drop target
                onDrop = { state -> // Data passed from the draggable item
                    if(state.key != folderDto.id)
                        onDrop(state.data, folderDto)
                }
            ),
            onClick = onClick,
            actions = actions,
            onAction = onAction,
        )
    }
}

@Composable
private fun makeDraggableIfNeed(
    folderDto: FolderDto,
    state: DragAndDropState<ContractsDragAndDropData>,
    content: @Composable () -> Unit = {},
) {
    if (!folderDto.archive)
        DraggableItem(
            state = state,
            key = folderDto.id,
            data = ContractsDragAndDropData(folder = folderDto),
            draggableContent = { CardBase(title = folderDto.name, modifier = Modifier.scale(0.5f)) },
        ) {
            content()
        }
    else
        content()
}