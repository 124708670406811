package novasoft.roads.dto.client_common

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
class FolderDto(
    @SerialName("i")
    val id: Long = -1L,
    @SerialName("n")
    val name: String,
    @SerialName("c")
    val companyId: String,
    @SerialName("cs")
    val contractIds: MutableSet<Int>,
    @SerialName("ch")
    val children: MutableSet<Long>,
    @SerialName("p")
    val parent: Long?,
    @SerialName("a")
    val archive: Boolean
)