package ru.novasoft.roads.compose_client.core.ui.menu

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.selection.selectable
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Help
import androidx.compose.material.icons.filled.ChevronLeft
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import ru.novasoft.roads.compose_client.core.designsystem.theme.menu
import ru.novasoft.roads.compose_client.core.ui.DefaultToolTip


@Composable
fun PageHeaderView(
    name: String,
    contract: PageHeaderContract = object : PageHeaderContract {
        override fun onPageHeaderEvent(event: PageHeaderEvent) {
            Logger.w("Undefined PageHeaderContract") { "Page <$name> has undefined page header logic." }
        }
    }
) {
    val tabs = contract.getTabs()
    val selectedTabInd = mutableStateOf(0)
    Box(
        modifier = Modifier.fillMaxSize(),
        contentAlignment = Alignment.BottomCenter,
    ) {
        Card(
            modifier = Modifier.fillMaxWidth().height(40.dp).clip(RoundedCornerShape(topStart = 20.dp)),
            colors = CardDefaults.cardColors(containerColor = if (tabs.isEmpty()) MaterialTheme.colorScheme.surfaceContainer else MaterialTheme.colorScheme.surfaceVariant),
            shape = RectangleShape,
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically,
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(0.5f),
                ) {
                    tabs.toList().forEachIndexed { ind, tab ->
                        PageHeaderTab(ind, tab.first, selectedTabInd) { tab.second() }
                    }
                }
                Row(
                    modifier = Modifier.fillMaxWidth(0.5f),
                    horizontalArrangement = Arrangement.spacedBy(5.dp, Alignment.End),
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    DefaultToolTip(
                        text = "Назад"
                    ) {
                        IconButton(onClick = { contract.onPageHeaderEvent(PageHeaderEvent.BackClicked) }) {
                            Icon(
                                Icons.Filled.ChevronLeft,
                                "Back",
                                tint = MaterialTheme.colorScheme.primary,
                            )
                        }
                    }
                    DefaultToolTip(
                        text = "Помощь"
                    ) {
                        IconButton(onClick = { contract.onPageHeaderEvent(PageHeaderEvent.HelpClicked) }) {
                            Icon(
                                Icons.AutoMirrored.Filled.Help,
                                "Help",
                                tint = MaterialTheme.colorScheme.primary,
                            )
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun PageHeaderTab(ind: Int, name: String, selectedInd: MutableState<Int>, onSelect: () -> Unit) {
    Card(
        modifier = Modifier.width(200.dp).fillMaxHeight()
            .selectable(selectedInd.value == ind) {
                selectedInd.value = ind
                onSelect()
            },
        shape = if (ind == 0) RoundedCornerShape(topStart = 20.dp) else RectangleShape,
        colors = CardDefaults.cardColors().copy(
            containerColor = if (selectedInd.value == ind) MaterialTheme.colorScheme.background else MaterialTheme.colorScheme.surfaceVariant,
            contentColor = MaterialTheme.colorScheme.onBackground,
        )

    ) {
        Text(
            text = name,
            modifier = Modifier.fillMaxSize().wrapContentHeight(align = Alignment.CenterVertically),
            style = MaterialTheme.typography.menu,
            textAlign = TextAlign.Center
        )
    }
}