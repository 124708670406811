package src.novasoft.roads.compose_client.feature.menu.contract_screen

import common.RoadsUiEvent
import common.RoadsViewModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import novasoft.roads.dto.plan.res_sheet_history.ResourcesSheetHistoryDto
import novasoft.roads.dto.resource.ResourceDto
import novasoft.roads.dto.svor.SvorEntryDto
import ru.novasoft.roads.compose_client.core.data.remote.IAppStateInfoRepository
import ru.novasoft.roads.compose_client.core.network.api.contract.IContractApi
import ru.novasoft.roads.compose_client.core.network.api.folders.IFoldersApi

class ContractPageViewModel(
    private val contractApi: IContractApi,
    private val foldersApi: IFoldersApi,
    private val contractId: Int,
    val appStateInfoRepository: IAppStateInfoRepository
): RoadsViewModel() {

    private val companyId = appStateInfoRepository.getCompanyId()

    private val _entries = MutableStateFlow<Array<SvorEntryDto>>(emptyArray())
    val entries = _entries.asStateFlow()
    private val _resources = MutableStateFlow<List<ResourceDto>>(emptyList())
    val resources = _resources.asStateFlow()
    private val _resourcesSheets = MutableStateFlow<List<ResourcesSheetHistoryDto>>(emptyList())
    val resourcesSheets = _resourcesSheets.asStateFlow()

    init {
        with(viewModelScope) {
            launch { emitEntries() }
            launch { emitResources() }
            launch { emitResourcesSheets() }
        }
    }

    private fun emitEntries() = viewModelScope.launch{ _entries.emit(contractApi.getSVOREntries(contractId).await()) }
    private fun emitResources() = viewModelScope.launch{ _resources.emit(foldersApi.getCompanyResources(companyId).await()) }
    private fun emitResourcesSheets() = viewModelScope.launch{ _resourcesSheets.emit(contractApi.getResourcesUsageHistory(contractId).await()) }

    override fun onEvent(uiEvent: RoadsUiEvent) {
        TODO("Not yet implemented")
    }
}