package ru.novasoft.roads.compose_client.core.network.api.company_assignment

import novasoft.roads.dto.company_team.company.CompanyContractsDto
import novasoft.roads.dto.company_team.company.CustomerAssignmentDto
import novasoft.roads.dto.company_team.company.SubcontractorAssignmentDto
import novasoft.roads.dto.company_team.subcontract.SubcontractorChangesDto
import novasoft.roads.dto.company_team.subcontract.SubcontractorsAndCompaniesDto
import novasoft.roads.dto.svor.SvorEntryLightDto
import ru.novasoft.roads.compose_client.core.network.client.ClientMapper
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient

class CompanyAssignmentApi(override val client: RoadsClient) : ClientMapper(client, "company_assignment"),
    ICompanyAssignmentApi {
    override suspend fun getConnectedCompaniesInfo(companyId: String) =
        makeJsonRequest<List<CompanyContractsDto>>("get_connected_companies_info/$companyId")

    override suspend fun getConnectedCompaniesIDName(companyId: String) =
        makeJsonRequest<Map<String, String>>("get_connected_companies_id_name/$companyId")

    override suspend fun getActualContractsNames(companyId: String) =
        makeJsonRequest<Map<Int, String>>("get_actual_contracts_names/$companyId")

    override suspend fun getActualContractsNamesForCustomerAssignment(companyId: String) =
        makeJsonRequest<Map<Int, String>>("get_actual_contracts_names_for_customer_assignment/$companyId")

    override suspend fun saveCustomerAssignment(customerAssignment: CustomerAssignmentDto, userCompanyId: String) =
        httpSendJsonGetString(
            "save_customer_assignment/$userCompanyId",
            customerAssignment
        )

    override suspend fun getContractsMeterRanges(companyId: String) =
        makeJsonRequest<Map<Int, Pair<Float, Float>>>("get_contracts_meter_ranges/$companyId")

    override suspend fun getSVOREntriesForSubcontractor(companyId: String, contractId: Int) =
        makeJsonRequest<Array<SvorEntryLightDto>>("get_svor_entries_for_subcontractor/$companyId/$contractId")

    override suspend fun saveSubcontractorAssignment(
        subcontractorAssignment: SubcontractorAssignmentDto,
        companyId: String,
    ) = httpSendJsonGetString("save_subcontractor_assignment/$companyId", subcontractorAssignment)

    override suspend fun isSubcontract(contractId: Int) =
        makeJsonRequest<Boolean>("is_subcontract/$contractId")

    override suspend fun getSubcontractorsAndCompanies(companyId: String) =
        makeJsonRequest<SubcontractorsAndCompaniesDto>("get_subcontractors_and_companies/$companyId")

    override suspend fun changeSubcontractorRecords(
        changes: List<SubcontractorChangesDto>,
        companyId: String,
    ) = httpSendJsonGetString("$companyId/change_subcontractor_records", changes)

    override suspend fun deleteSubContractorRecord(subcontractorId: String, companyId: String) =
        httpSendRequestString("$companyId/delete_subcontractor_record/$subcontractorId")
}