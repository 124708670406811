package novasoft.roads.dto.resource

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ResourceRuleDto(
    @SerialName("tt")
    val taskTypeId: Long? = null,
    @SerialName("c")
    val cargoId: Long? = null,
    @SerialName("r")
    val resultResourceId: Long = -1
)