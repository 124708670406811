package novasoft.roads.util

import kotlinx.datetime.*
import kotlinx.datetime.format.FormatStringsInDatetimeFormats
import kotlinx.datetime.format.byUnicodePattern
import novasoft.roads.util.model.DateSliceModel

const val DATE_FORMAT = "dd.MM.yyyy"
const val DATE_TIME_FORMAT = "$DATE_FORMAT HH:mm:ss"

@OptIn(FormatStringsInDatetimeFormats::class)
val DEFAULT_DATE_FORMAT = LocalDate.Format {
    byUnicodePattern(DATE_FORMAT)
}

@OptIn(FormatStringsInDatetimeFormats::class)
val DEFAULT_DATE_TIME_FORMAT = LocalDateTime.Format {
    byUnicodePattern(DATE_TIME_FORMAT)
}

fun Long.toLocalDateTime(): LocalDateTime {
    return DateUtils.UTCMSToLocalDateTime(this)
}

fun Long.toLocalDate(): LocalDate {
    return DateUtils.UTCMSToLocalDate(this)
}

fun Long.toDateString(): String {
    return DEFAULT_DATE_FORMAT.format(this.toLocalDate())
}

fun Long.toTimeString(): String {
    return this.toDateTimeString().split(" ")[1]
}

fun Long.toDateTimeString(): String {
    return DEFAULT_DATE_TIME_FORMAT.format(this.toLocalDateTime())
}

fun LocalDate.prettyFormat(): String {
    return "${DateUtils.formatDateToDayOfMonth(this)} ${this.year}"
}

fun LocalDate.toUTCMS(): Long {
    return DateUtils.localDateToUTCMS(this)
}

fun LocalDateTime.toUTCMS(): Long {
    return DateUtils.localDateToUTCMS(this)
}

fun LocalDate.toDefaultFormat(): String {
    return DEFAULT_DATE_FORMAT.format(this)
}

fun LocalDateTime.toDefaultFormat(): String {
    return DEFAULT_DATE_TIME_FORMAT.format(this)
}

/**
 * Парсит дату из строки в формате dd.MM.yyyy
 */
fun String.toLocalDateOrNull(): LocalDate? = runCatching {
    DEFAULT_DATE_FORMAT.parse(this)
}.getOrNull()

object DateUtils {

    val MONTH_RUSSIAN = mapOf(
        1 to "Январь",
        2 to "Февраль",
        3 to "Март",
        4 to "Апрель",
        5 to "Май",
        6 to "Июнь",
        7 to "Июль",
        8 to "Август",
        9 to "Сентябрь",
        10 to "Октябрь",
        11 to "Ноябрь",
        12 to "Декабрь",
    )

    val DAY_OF_WEEK_RUSSIAN = mapOf(
        1 to "Пн",
        2 to "Вт",
        3 to "Ср",
        4 to "Чт",
        5 to "Пт",
        6 to "Сб",
        7 to "Вс",
    )

    val DEFAULT_TIME_ZONE = TimeZone.currentSystemDefault()

    fun formatDateToDayOfMonth(date: LocalDate): String {
        var month = MONTH_RUSSIAN[date.month.number]!!.lowercase()
        val len = month.length
        month = month.substring(0, 3)
        if (len > 3) {
            month += "."
        }
        return "${date.dayOfMonth} $month"
    }

    fun localDateToUTCMS(date: LocalDateTime): Long {
        return date.toInstant(DEFAULT_TIME_ZONE).toEpochMilliseconds()
    }

    fun localDateToUTCMS(date: LocalDate): Long {
        return LocalDateTime(date, LocalTime(0, 0)).toInstant(DEFAULT_TIME_ZONE).toEpochMilliseconds()
    }

    fun UTCMSToLocalDateTime(epoch: Long): LocalDateTime {
        return Instant.fromEpochMilliseconds(epoch).toLocalDateTime(DEFAULT_TIME_ZONE)
    }

    fun UTCMSToLocalDate(epoch: Long): LocalDate {
        return UTCMSToLocalDateTime(epoch).date
    }

    /**
     * По переданному номеру квартала возвращаем его название, если 1..4, иначе null
     */
    fun getQuarterName(quarter: Int?): String? {
        return if (quarter == null || !(1..4).contains(quarter)) null
        else "$quarter КВАРТАЛ"
    }

    fun getQuarterMonths(quarter: Int?): Set<Int> {
        return if (quarter == null || !(1..4).contains(quarter)) emptySet()
        else (1..3).map { (quarter - 1) * 3 + it }.toSet()
    }

    /**
     * Возвращает промежуток от 1 января текущего года, до текущей даты включительно
     */
    fun getYTDSlice(): DateSliceModel {
        val now = Clock.System.now().toLocalDateTime(DEFAULT_TIME_ZONE).date
        return DateSliceModel(LocalDate(now.year, 1, 1).toUTCMS(), now.toUTCMS())
    }

    fun nowUTCMS() = Clock.System.now().epochSeconds
}