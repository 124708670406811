package ru.novasoft.roads.compose_client.core.designsystem.theme
import androidx.compose.ui.graphics.Color

val primaryLight = Color(0xFF38608F)
val onPrimaryLight = Color(0xFFFFFFFF)
val primaryContainerLight = Color(0xFFD3E4FF)
val onPrimaryContainerLight = Color(0xFF001C38)
val secondaryLight = Color(0xFF545F70)
val onSecondaryLight = Color(0xFFFFFFFF)
val secondaryContainerLight = Color(0xFFD7E3F8)
val onSecondaryContainerLight = Color(0xFF101C2B)
val tertiaryLight = Color(0xFF6C5677)
val onTertiaryLight = Color(0xFFFFFFFF)
val tertiaryContainerLight = Color(0xFFF4D9FF)
val onTertiaryContainerLight = Color(0xFF261431)
val errorLight = Color(0xFFBA1A1A)
val onErrorLight = Color(0xFFFFFFFF)
val errorContainerLight = Color(0xFFFFDAD6)
val onErrorContainerLight = Color(0xFF410002)
val backgroundLight = Color(0xFFF8F9FF)
val onBackgroundLight = Color(0xFF191C20)
val surfaceLight = Color(0xFFF8F9FF)
val onSurfaceLight = Color(0xFF191C20)
val surfaceVariantLight = Color(0xFFDFE2EB)
val onSurfaceVariantLight = Color(0xFF43474E)
val outlineLight = Color(0xFF73777F)
val outlineVariantLight = Color(0xFFC3C6CF)
val scrimLight = Color(0xFF000000)
val inverseSurfaceLight = Color(0xFF2E3035)
val inverseOnSurfaceLight = Color(0xFFEFF0F7)
val inversePrimaryLight = Color(0xFFA2C9FE)
val surfaceDimLight = Color(0xFFD8DAE0)
val surfaceBrightLight = Color(0xFFF8F9FF)
val surfaceContainerLowestLight = Color(0xFFFFFFFF)
val surfaceContainerLowLight = Color(0xFFF2F3FA)
val surfaceContainerLight = Color(0xFFECEDF4)
val surfaceContainerHighLight = Color(0xFFE7E8EE)
val surfaceContainerHighestLight = Color(0xFFE1E2E8)

val primaryLightMediumContrast = Color(0xFF184471)
val onPrimaryLightMediumContrast = Color(0xFFFFFFFF)
val primaryContainerLightMediumContrast = Color(0xFF5077A7)
val onPrimaryContainerLightMediumContrast = Color(0xFFFFFFFF)
val secondaryLightMediumContrast = Color(0xFF384454)
val onSecondaryLightMediumContrast = Color(0xFFFFFFFF)
val secondaryContainerLightMediumContrast = Color(0xFF6A7587)
val onSecondaryContainerLightMediumContrast = Color(0xFFFFFFFF)
val tertiaryLightMediumContrast = Color(0xFF4F3B5A)
val onTertiaryLightMediumContrast = Color(0xFFFFFFFF)
val tertiaryContainerLightMediumContrast = Color(0xFF836C8F)
val onTertiaryContainerLightMediumContrast = Color(0xFFFFFFFF)
val errorLightMediumContrast = Color(0xFF8C0009)
val onErrorLightMediumContrast = Color(0xFFFFFFFF)
val errorContainerLightMediumContrast = Color(0xFFDA342E)
val onErrorContainerLightMediumContrast = Color(0xFFFFFFFF)
val backgroundLightMediumContrast = Color(0xFFF8F9FF)
val onBackgroundLightMediumContrast = Color(0xFF191C20)
val surfaceLightMediumContrast = Color(0xFFF8F9FF)
val onSurfaceLightMediumContrast = Color(0xFF191C20)
val surfaceVariantLightMediumContrast = Color(0xFFDFE2EB)
val onSurfaceVariantLightMediumContrast = Color(0xFF3F434A)
val outlineLightMediumContrast = Color(0xFF5B5F67)
val outlineVariantLightMediumContrast = Color(0xFF777B83)
val scrimLightMediumContrast = Color(0xFF000000)
val inverseSurfaceLightMediumContrast = Color(0xFF2E3035)
val inverseOnSurfaceLightMediumContrast = Color(0xFFEFF0F7)
val inversePrimaryLightMediumContrast = Color(0xFFA2C9FE)
val surfaceDimLightMediumContrast = Color(0xFFD8DAE0)
val surfaceBrightLightMediumContrast = Color(0xFFF8F9FF)
val surfaceContainerLowestLightMediumContrast = Color(0xFFFFFFFF)
val surfaceContainerLowLightMediumContrast = Color(0xFFF2F3FA)
val surfaceContainerLightMediumContrast = Color(0xFFECEDF4)
val surfaceContainerHighLightMediumContrast = Color(0xFFE7E8EE)
val surfaceContainerHighestLightMediumContrast = Color(0xFFE1E2E8)

val primaryLightHighContrast = Color(0xFF002343)
val onPrimaryLightHighContrast = Color(0xFFFFFFFF)
val primaryContainerLightHighContrast = Color(0xff222d3c)
val onPrimaryContainerLightHighContrast = Color(0xFFFFFFFF)
val secondaryLightHighContrast = Color(0xFF172332)
val onSecondaryLightHighContrast = Color(0xFFFFFFFF)
val secondaryContainerLightHighContrast = Color(0xFF384454)
val onSecondaryContainerLightHighContrast = Color(0xFFFFFFFF)
val tertiaryLightHighContrast = Color(0xFF2D1B38)
val onTertiaryLightHighContrast = Color(0xFFFFFFFF)
val tertiaryContainerLightHighContrast = Color(0xFF4F3B5A)
val onTertiaryContainerLightHighContrast = Color(0xFFFFFFFF)
val errorLightHighContrast = Color(0xFF4E0002)
val onErrorLightHighContrast = Color(0xFFFFFFFF)
val errorContainerLightHighContrast = Color(0xFF8C0009)
val onErrorContainerLightHighContrast = Color(0xFFFFFFFF)
val backgroundLightHighContrast = Color(0xFFF8F9FF)
val onBackgroundLightHighContrast = Color(0xFF191C20)
val surfaceLightHighContrast = Color(0xFFF8F9FF)
val onSurfaceLightHighContrast = Color(0xFF000000)
val surfaceVariantLightHighContrast = Color(0xFFDFE2EB)
val onSurfaceVariantLightHighContrast = Color(0xFF20242B)
val outlineLightHighContrast = Color(0xFF3F434A)
val outlineVariantLightHighContrast = Color(0xFF3F434A)
val scrimLightHighContrast = Color(0xFF000000)
val inverseSurfaceLightHighContrast = Color(0xFF2E3035)
val inverseOnSurfaceLightHighContrast = Color(0xFFFFFFFF)
val inversePrimaryLightHighContrast = Color(0xFFE2EDFF)
val surfaceDimLightHighContrast = Color(0xFFD8DAE0)
val surfaceBrightLightHighContrast = Color(0xFFF8F9FF)
val surfaceContainerLowestLightHighContrast = Color(0xFFFFFFFF)
val surfaceContainerLowLightHighContrast = Color(0xFFF2F3FA)
val surfaceContainerLightHighContrast = Color(0xFFECEDF4)
val surfaceContainerHighLightHighContrast = Color(0xFFE7E8EE)
val surfaceContainerHighestLightHighContrast = Color(0xFFE1E2E8)

val primaryDark = Color(0xFFA2C9FE)
val onPrimaryDark = Color(0xFF00325B)
val primaryContainerDark = Color(0xFF1D4875)
val onPrimaryContainerDark = Color(0xFFD3E4FF)
val secondaryDark = Color(0xFFBBC7DB)
val onSecondaryDark = Color(0xFF263141)
val secondaryContainerDark = Color(0xFF3C4858)
val onSecondaryContainerDark = Color(0xFFD7E3F8)
val tertiaryDark = Color(0xFFD8BDE3)
val onTertiaryDark = Color(0xFF3C2947)
val tertiaryContainerDark = Color(0xFF533F5F)
val onTertiaryContainerDark = Color(0xFFF4D9FF)
val errorDark = Color(0xFFFFB4AB)
val onErrorDark = Color(0xFF690005)
val errorContainerDark = Color(0xFF93000A)
val onErrorContainerDark = Color(0xFFFFDAD6)
val backgroundDark = Color(0xFF111418)
val onBackgroundDark = Color(0xFFE1E2E8)
val surfaceDark = Color(0xFF111418)
val onSurfaceDark = Color(0xFFE1E2E8)
val surfaceVariantDark = Color(0xFF43474E)
val onSurfaceVariantDark = Color(0xFFC3C6CF)
val outlineDark = Color(0xFF8D9199)
val outlineVariantDark = Color(0xFF43474E)
val scrimDark = Color(0xFF000000)
val inverseSurfaceDark = Color(0xFFE1E2E8)
val inverseOnSurfaceDark = Color(0xFF2E3035)
val inversePrimaryDark = Color(0xFF38608F)
val surfaceDimDark = Color(0xFF111418)
val surfaceBrightDark = Color(0xFF37393E)
val surfaceContainerLowestDark = Color(0xFF0B0E13)
val surfaceContainerLowDark = Color(0xFF191C20)
val surfaceContainerDark = Color(0xFF1D2024)
val surfaceContainerHighDark = Color(0xFF272A2F)
val surfaceContainerHighestDark = Color(0xFF32353A)

val primaryDarkMediumContrast = Color(0xFFA9CDFF)
val onPrimaryDarkMediumContrast = Color(0xFF00172F)
val primaryContainerDarkMediumContrast = Color(0xFF6D93C5)
val onPrimaryContainerDarkMediumContrast = Color(0xFF000000)
val secondaryDarkMediumContrast = Color(0xFFC0CBE0)
val onSecondaryDarkMediumContrast = Color(0xFF0B1725)
val secondaryContainerDarkMediumContrast = Color(0xFF8691A4)
val onSecondaryContainerDarkMediumContrast = Color(0xFF000000)
val tertiaryDarkMediumContrast = Color(0xFFDCC1E8)
val onTertiaryDarkMediumContrast = Color(0xFF200F2B)
val tertiaryContainerDarkMediumContrast = Color(0xFFA088AC)
val onTertiaryContainerDarkMediumContrast = Color(0xFF000000)
val errorDarkMediumContrast = Color(0xFFFFBAB1)
val onErrorDarkMediumContrast = Color(0xFF370001)
val errorContainerDarkMediumContrast = Color(0xFFFF5449)
val onErrorContainerDarkMediumContrast = Color(0xFF000000)
val backgroundDarkMediumContrast = Color(0xFF111418)
val onBackgroundDarkMediumContrast = Color(0xFFE1E2E8)
val surfaceDarkMediumContrast = Color(0xFF111418)
val onSurfaceDarkMediumContrast = Color(0xFFFAFAFF)
val surfaceVariantDarkMediumContrast = Color(0xFF43474E)
val onSurfaceVariantDarkMediumContrast = Color(0xFFC7CBD3)
val outlineDarkMediumContrast = Color(0xFF9FA3AB)
val outlineVariantDarkMediumContrast = Color(0xFF7F838B)
val scrimDarkMediumContrast = Color(0xFF000000)
val inverseSurfaceDarkMediumContrast = Color(0xFFE1E2E8)
val inverseOnSurfaceDarkMediumContrast = Color(0xFF272A2F)
val inversePrimaryDarkMediumContrast = Color(0xFF1F4A77)
val surfaceDimDarkMediumContrast = Color(0xFF111418)
val surfaceBrightDarkMediumContrast = Color(0xFF37393E)
val surfaceContainerLowestDarkMediumContrast = Color(0xFF0B0E13)
val surfaceContainerLowDarkMediumContrast = Color(0xFF191C20)
val surfaceContainerDarkMediumContrast = Color(0xFF1D2024)
val surfaceContainerHighDarkMediumContrast = Color(0xFF272A2F)
val surfaceContainerHighestDarkMediumContrast = Color(0xFF32353A)

val primaryDarkHighContrast = Color(0xFFFAFAFF)
val onPrimaryDarkHighContrast = Color(0xFF000000)
val primaryContainerDarkHighContrast = Color(0xFFA9CDFF)
val onPrimaryContainerDarkHighContrast = Color(0xFF000000)
val secondaryDarkHighContrast = Color(0xFFFAFAFF)
val onSecondaryDarkHighContrast = Color(0xFF000000)
val secondaryContainerDarkHighContrast = Color(0xFFC0CBE0)
val onSecondaryContainerDarkHighContrast = Color(0xFF000000)
val tertiaryDarkHighContrast = Color(0xFFFFF9FB)
val onTertiaryDarkHighContrast = Color(0xFF000000)
val tertiaryContainerDarkHighContrast = Color(0xFFDCC1E8)
val onTertiaryContainerDarkHighContrast = Color(0xFF000000)
val errorDarkHighContrast = Color(0xFFFFF9F9)
val onErrorDarkHighContrast = Color(0xFF000000)
val errorContainerDarkHighContrast = Color(0xFFFFBAB1)
val onErrorContainerDarkHighContrast = Color(0xFF000000)
val backgroundDarkHighContrast = Color(0xFF111418)
val onBackgroundDarkHighContrast = Color(0xFFE1E2E8)
val surfaceDarkHighContrast = Color(0xFF111418)
val onSurfaceDarkHighContrast = Color(0xFFFFFFFF)
val surfaceVariantDarkHighContrast = Color(0xFF43474E)
val onSurfaceVariantDarkHighContrast = Color(0xFFFAFAFF)
val outlineDarkHighContrast = Color(0xFFC7CBD3)
val outlineVariantDarkHighContrast = Color(0xFFC7CBD3)
val scrimDarkHighContrast = Color(0xFF000000)
val inverseSurfaceDarkHighContrast = Color(0xFFE1E2E8)
val inverseOnSurfaceDarkHighContrast = Color(0xFF000000)
val inversePrimaryDarkHighContrast = Color(0xFF002B50)
val surfaceDimDarkHighContrast = Color(0xFF111418)
val surfaceBrightDarkHighContrast = Color(0xFF37393E)
val surfaceContainerLowestDarkHighContrast = Color(0xFF0B0E13)
val surfaceContainerLowDarkHighContrast = Color(0xFF191C20)
val surfaceContainerDarkHighContrast = Color(0xFF1D2024)
val surfaceContainerHighDarkHighContrast = Color(0xFF272A2F)
val surfaceContainerHighestDarkHighContrast = Color(0xFF32353A)


val uiRedLightColor = Color(200, 75, 75)
val uiBrightRedLightColor = Color(255, 200, 200)
val uiYellowLightColor = Color(255, 153, 51)
val planLightColor = Color(87, 41, 139)
val planBrightLightColor = Color(200, 145, 230)
val doneLightColor = Color(34, 116, 191)
val doneBrightLightColor = Color(157, 195, 230)
val reportedLightColor = Color(25, 150, 25)
val reportedBrightLightColor = Color(202, 238, 184)
val contrastGreenLightColor = Color(30, 90, 30)

val uiRedDarkColor = Color(250, 128, 114)
val uiBrightRedDarkColor = Color(250, 128, 114)
val uiYellowDarkColor = Color(250, 182, 86)
val planDarkColor = Color(187, 141, 239)
val planBrightDarkColor = Color(187, 141, 239)
val doneDarkColor = Color(94, 176, 251)
val doneBrightDarkColor = Color(94, 176, 251)
val reportedDarkColor = Color(52,201,36)
val reportedBrightDarkColor = Color(52,201,36)
val contrastGreenDarkColor = reportedBrightLightColor
