package ru.novasoft.roads.compose_client.core.model.dto

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Код ответа, получаемого с сервера.
 */
@Serializable
enum class Code(
    var httpStatus: String,
    var errorMessage: String,
) {
    @SerialName("OK")
    OK(
        "OK",
        "УСПЕШНЫЙ ЗАПРОС"
    ),

    @SerialName("Bad Request")
    BAD_REQUEST(
        "BAD_REQUEST",
        "НЕВЕРНЫЙ ЗАПРОС"
    ),

    @SerialName("Unauthorized")
    UNAUTHORIZED(
        "UNAUTHORIZED",
        "ПОЛЬЗОВАТЕЛЬ НЕ АВТОРИЗОВАН"
    ),

    @SerialName("Forbidden")
    FORBIDDEN(
        "FORBIDDEN",
        "ЗАПРОС ЗАПРЕЩЕН"
    ),

    @SerialName("Not Found")
    NOT_FOUND(
        "NOT_FOUND",
        "ОТСУТСТВУЮТ ПРАВА ДОСТУПА"
    ),

    @SerialName("Internal Server Error")
    INTERNAL_SERVER_ERROR(
        "INTERNAL_SERVER_ERROR",
        "ВНУТРЕНЯЯ ОШИБКА СЕРВЕРА"
    ),

    @SerialName("Service Unavailable")
    SERVICE_UNAVAILABLE("SERVICE_UNAVAILABLE", "СЕРВИС НЕДОСТУПЕН"),

    @SerialName("Gateway Timeout")
    GATEWAY_TIMEOUT(
        "GATEWAY_TIMEOUT",
        "ВРЕМЯ ОЖИДАНИЯ ИСТЕКЛО"
    );
}