package src.novasoft.roads.compose_client.feature.auth

import common.RoadsAppEvent
import common.RoadsException
import common.RoadsUiEvent

sealed class AuthUiEvent: RoadsUiEvent {
    data class LoginChanged(val login: String): AuthUiEvent()
    data class PasswordChanged(val password: String): AuthUiEvent()
    object OkButtonPressed: AuthUiEvent()
}

data class AuthState(
    val login: String = "",
    val password: String = ""
)

sealed interface AuthUiState: RoadsAppEvent {
    data object SuccessSignIn : AuthUiState
    data object Failure : AuthUiState
}

class IsNotAuthUiEvent: RoadsException, Exception()