package ru.novasoft.roads.compose_client.core.ui.editors

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.PopupProperties

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun FilterDropDownMenu(
    selectedOption: String?,
    label: String,
    options: List<String>,
    validate: (String?) -> String? = { null },
    onSelect: (selectedInd: Int, option: String) -> Unit,
) {
    val indexed = options.mapIndexed { ind, s -> ind to s }.toMap()
    var filtered by remember { mutableStateOf(indexed) }
    var expanded by remember { mutableStateOf(false) }
    var textValue by remember { mutableStateOf(selectedOption ?: "") }

    ValidationWrapper(selectedOption, validate) { errText, isError ->
        ExposedDropdownMenuBox(
            expanded = expanded,
            onExpandedChange = {
                expanded = !expanded
            },
        ) {
            TextField(
                value = textValue,
                onValueChange = {
                    textValue = it
                    filtered = indexed.filter { o ->
                        textValue.let { vle -> o.value.lowercase().startsWith(vle.lowercase()) }
                    }
                    expanded = filtered.isNotEmpty()
                },
                trailingIcon = {
                    ExposedDropdownMenuDefaults.TrailingIcon(expanded = expanded)
                },
                label = { Text(text = label) },
                singleLine = true,
                modifier = Modifier.fillMaxWidth().menuAnchor(MenuAnchorType.PrimaryEditable, true),
                textStyle = MaterialTheme.typography.bodyLarge,
                colors = TextFieldDefaults.colors(
                    unfocusedContainerColor = MaterialTheme.colorScheme.surfaceContainerLow,
                    focusedContainerColor = MaterialTheme.colorScheme.surfaceContainerLowest
                ),
                isError = isError.value,
                supportingText = { SupportingText(errText) },
            )



            DropdownMenu(
                expanded = expanded,
                onDismissRequest = { expanded = false },
                modifier = Modifier.heightIn(max = 240.dp)
                    .exposedDropdownSize(true),
                properties = PopupProperties(focusable = false)
            ) {
                filtered.forEach { option ->
                    DropdownMenuItem(
                        text = { Text(text = option.value) },
                        onClick = {
                            textValue = option.value
                            onSelect(option.key, option.value)
                            expanded = false
                        },
                        contentPadding = ExposedDropdownMenuDefaults.ItemContentPadding,
                    )
                }
            }
        }
    }
}
