package novasoft.roads.dto.plan.res_sheet_history

import kotlinx.serialization.Serializable

@Serializable
data class ResourcesSheetHistoryDto(
    val id: Long,
    val name: String,
    val date: Long,
    val contractId: Int,
    val workTypesResUsage: List<WorkTypeHistoryDto>
)