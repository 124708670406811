package ru.novasoft.roads.compose_client.core.data.di

import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.instance
import org.kodein.di.singleton
import ru.novasoft.roads.compose_client.core.data.IUserRepo
import ru.novasoft.roads.compose_client.core.data.UserRepo
import ru.novasoft.roads.compose_client.core.data.remote.IAppStateInfoRepository
import ru.novasoft.roads.compose_client.core.data.remote.IAuthRepository
import ru.novasoft.roads.compose_client.core.data.remote.ITokenRepository
import ru.novasoft.roads.compose_client.core.data.remote.impl.AppStateInfoRepository
import ru.novasoft.roads.compose_client.core.data.remote.impl.AuthRepository
import ru.novasoft.roads.compose_client.core.data.remote.impl.TokenRepository
import ru.novasoft.roads.compose_client.core.network.di.sharedNetworkDI

fun sharedDataDI() = DI {
    import(sharedDataModule())
    extend(di = sharedNetworkDI())
}

fun sharedDataModule() = DI.Module("DataModule") {
    bind<IAuthRepository>() with singleton { AuthRepository(instance()) }
    bind<ITokenRepository>() with singleton { TokenRepository() }
    bind<IUserRepo>() with singleton { UserRepo() }
    bind<IAppStateInfoRepository>() with singleton { AppStateInfoRepository() }
}