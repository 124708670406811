package ru.novasoft.roads.compose_client.core.ui.editors

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier

@Composable
fun <T> ValidationWrapper(
    initialValue: T?,
    validate: (T?) -> String? = { null },
    content: @Composable (errText: MutableState<String?>, isError: MutableState<Boolean>) -> Unit
) {
    val errorTextState = remember { mutableStateOf(validate(initialValue)) }
    val isErrorState = remember { mutableStateOf(errorTextState.value != null) }
    content(errorTextState, isErrorState)
}

@Composable
fun SupportingText(state: MutableState<String?>) {
    if (state.value != null) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.Start,
        ) {
            Text(
                text = state.value!!,
                color = MaterialTheme.colorScheme.error,
                style = MaterialTheme.typography.bodySmall,
            )
        }
    }

}
