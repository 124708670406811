package src.novasoft.roads.compose_client.feature.menu.contracts.contractCard

import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Reply
import androidx.compose.material.icons.filled.*
import androidx.compose.material3.Icon
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import com.mohamedrejeb.compose.dnd.DragAndDropState
import com.mohamedrejeb.compose.dnd.drag.DraggableItem
import novasoft.roads.dto.client_common.ContractDto
import novasoft.roads.dto.client_common.ContractType
import novasoft.roads.dto.meter_progress.ProgressStatus
import novasoft.roads.util.formatBitDepth
import novasoft.roads.util.toDateString
import ru.novasoft.roads.compose_client.core.designsystem.theme.contrastGreenColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.doneColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.planColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.reportedColor
import src.novasoft.roads.compose_client.feature.menu.contracts.CardBase
import src.novasoft.roads.compose_client.feature.menu.contracts.ContractCardParams
import src.novasoft.roads.compose_client.feature.menu.contracts.ContractsDragAndDropData
import src.novasoft.roads.compose_client.feature.menu.contracts.ContractsUiEvent
import kotlin.math.roundToInt

@Composable
fun ContractCardView(
    contractDto: ContractDto,
    param: ContractCardParams,
    dragAndDropState: DragAndDropState<ContractsDragAndDropData>,
    onClick: () -> Unit = {},
    actions: List<ContractsUiEvent.CardBaseAction.ContractCardAction> = emptyList(),
    onAction: (ContractsUiEvent.CardBaseAction) -> Unit = {},
) {
    DraggableItem(
        state = dragAndDropState,
        key = contractDto.contractId,
        data = ContractsDragAndDropData(contract = contractDto),
        draggableContent = {
            CardBase(
                contractDto.name,
                contractDto.type.text,
                contractDto.type.icon,
                modifier = Modifier.scale(0.5f)
            )
        }
    ) {
        CardBase(
            contractDto.name,
            contractDto.type.text,
            contractDto.type.icon,
            onClick = onClick,
            actions = actions,
            onAction = onAction,
        ) {

            Column(
                verticalArrangement = Arrangement.Top,
                modifier = Modifier.padding(vertical = 5.dp),
            ) {
                ContractInfoTip(
                    Icons.Filled.CurrencyRuble,
                    "Сумма",
                    contractDto.worth.formatBitDepth()
                )
                ContractInfoTip(
                    Icons.Filled.RequestQuote,
                    "Номер",
                    contractDto.contractNumber
                )
                ContractInfoTip(
                    Icons.Filled.Person,
                    "Заказчик",
                    contractDto.customerName,
                )
                ContractInfoTip(
                    Icons.Filled.CalendarToday,
                    "Начало",
                    contractDto.startDate.toDateString(),
                )
                ContractInfoTip(
                    param.progressStatus.icon,
                    "Статус",
                    param.progressStatus.text,
                )
            }


            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically,
            ) {

                LinearProgressIndicator(
                    progress = { param.progress.toFloat() },
                    modifier = Modifier.fillMaxWidth(0.85f).height(8.dp),
                    color = param.progressStatus.color,
                    trackColor = param.progressStatus.color.copy(alpha = 0.1f),
                    gapSize = 0.dp,
                    drawStopIndicator = {}
                )
                Text(
                    "— ${(param.progress * 100).roundToInt()}%",
                    style = MaterialTheme.typography.bodySmall,
                    color = MaterialTheme.colorScheme.outlineVariant
                )
            }
        }
    }
}

@Composable
fun ContractInfoTip(
    imageVector: ImageVector,
    name: String,
    value: String,
) {
    Row(
        horizontalArrangement = Arrangement.spacedBy(5.dp),
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Icon(
            imageVector,
            null,
            modifier = Modifier.size(14.dp),
            tint = MaterialTheme.colorScheme.outlineVariant,
        )
        Text(
            "$name:",
            style = MaterialTheme.typography.bodySmall,
            color = MaterialTheme.colorScheme.outlineVariant
        )
        Text(
            value,
            style = MaterialTheme.typography.bodySmall,
        )
    }
}

private val ProgressStatus.icon
    @Composable get(): ImageVector = when (this) {
        ProgressStatus.PLAN -> Icons.Filled.Timelapse
        ProgressStatus.DONE -> Icons.Filled.Check
        ProgressStatus.REPORTED -> Icons.AutoMirrored.Filled.Reply
        ProgressStatus.ARCHIVE -> Icons.Filled.DoneAll
    }

private val ProgressStatus.color
    @Composable get(): Color = when (this) {
        ProgressStatus.PLAN -> MaterialTheme.colorScheme.planColor
        ProgressStatus.DONE -> MaterialTheme.colorScheme.doneColor
        ProgressStatus.REPORTED -> MaterialTheme.colorScheme.reportedColor
        ProgressStatus.ARCHIVE -> MaterialTheme.colorScheme.contrastGreenColor
    }

private val ProgressStatus.text
    @Composable get(): String = when (this) {
        ProgressStatus.PLAN -> "Планируется"
        ProgressStatus.DONE -> "Выполняется"
        ProgressStatus.REPORTED -> "Сдается"
        ProgressStatus.ARCHIVE -> "Завершен"
    }

val ContractType.text
    get(): String = when (this) {
        ContractType.CONSTRUCTION -> "Строительство"
        ContractType.RECONSTRUCTION -> "Реконструкция"
        ContractType.CAPITAL_REPAIR -> "Кап. ремонт"
        ContractType.REPAIR -> "Ремонт"
        ContractType.MAINTENANCE -> "Содержание"
        ContractType.NONE -> "Не указан"
    }

fun getContractTypeFromString(string: String): ContractType = when (string.lowercase()) {
    "строительство" -> ContractType.CONSTRUCTION
    "реконструкция" -> ContractType.RECONSTRUCTION
    "кап. ремонт" -> ContractType.CAPITAL_REPAIR
    "ремонт" -> ContractType.REPAIR
    "содержание" -> ContractType.MAINTENANCE
    else -> ContractType.NONE
}

private val ContractType.icon
    @Composable get(): ImageVector = when (this) {
        ContractType.CONSTRUCTION -> Icons.Filled.AddRoad
        ContractType.RECONSTRUCTION -> Icons.Filled.Autorenew
        ContractType.CAPITAL_REPAIR -> Icons.Filled.Construction
        ContractType.REPAIR -> Icons.Filled.Build
        ContractType.MAINTENANCE -> Icons.Filled.Emergency
        ContractType.NONE -> Icons.Filled.QuestionMark
    }
