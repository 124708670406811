package ru.novasoft.roads.compose_client.core.ui

import androidx.compose.material3.*
import androidx.compose.runtime.Composable


/**
 * @param text Текст, который будет отображаться в Tooltip
 * @param condition Условие, когда true - Tooltip будет установлен, иначе контент не будет оборачиваться
 */
@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun DefaultToolTip(text: String, condition: Boolean = true, content: @Composable () -> Unit) {
    if (condition)
        TooltipBox(
            positionProvider = TooltipDefaults.rememberPlainTooltipPositionProvider(),
            tooltip = {
                PlainTooltip {
                    Text(text)
                }
            },
            state = rememberTooltipState(),
            content = content
        )
    else
        content()
}
