package ru.novasoft.roads.compose_client.core.data.remote.impl

import ru.novasoft.roads.compose_client.core.data.remote.IAppStateInfoRepository
import ru.novasoft.roads.compose_client.core.model.model.ContractModel

class AppStateInfoRepository: IAppStateInfoRepository {
    private var companyId = "Компания ещё не назначена"

    override fun setCompanyId(companyId: String) { this.companyId = companyId }
    override fun getCompanyId() = companyId

    private var contract: ContractModel = ContractModel("Контракт не выбран")

    override fun setOpenedContract(contract: ContractModel) { this.contract = contract }
    override fun getOpenedContract() = contract
}