package ru.novasoft.roads.compose_client.core.network.api.report

import novasoft.roads.dto.client_common.CalendarPlanPropsDto
import novasoft.roads.dto.client_common.ContractDto
import novasoft.roads.dto.client_common.GlobalPlanReportForContractsPropertiesDto
import novasoft.roads.dto.client_common.OperationalPlanReportPropertiesDto
import novasoft.roads.dto.reports.ReportOptionDto
import novasoft.roads.dto.reports.report_properties.ContractReportPropertiesDto
import novasoft.roads.dto.reports.report_properties.ReportPropertiesFromDto
import novasoft.roads.dto.svor.SvorEntryReportTransactionDto
import ru.novasoft.roads.compose_client.core.network.client.ClientMapper
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient

class ReportApi(override val client: RoadsClient) : ClientMapper(client, "report"), IReportApi {
    override suspend fun downloadSvorPlanDiffExcel(contractId: Int) =
        httpSendJsonGetByteArray<String>("contract/$contractId/download_svor_plan_difference", null, 2)

    override suspend fun downloadWorkTypesAssignment(contractId: Int) =
        httpSendJsonGetByteArray<String>("contract/$contractId/download_work_types_assignment", null, 2)

    override suspend fun getCalendarPlanExcel(props: CalendarPlanPropsDto, contractId: Int) =
        httpSendJsonGetByteArray("contract/$contractId/calendar_plan", props, 5)

    override suspend fun getOperPlanReport(props: OperationalPlanReportPropertiesDto) =
        httpSendJsonGetByteArray("contract/${props.contractId}/oper_report", props, 5)

    override suspend fun getOperPlanReportForContracts(
        props: GlobalPlanReportForContractsPropertiesDto,
        companyId: String,
    ) = httpSendJsonGetByteArray("company/$companyId/oper_report_for_contracts", props, 5)

    override suspend fun fetchPlanFactReport(rp: ReportPropertiesFromDto?, contractId: Int) =
        httpSendJsonGetByteArray("$contractId/planfactreport", rp, 10)

    override suspend fun fetchSvorReport(rp: ContractReportPropertiesDto?, contractId: Int) =
        httpSendJsonGetByteArray("contract/$contractId/svor_report", rp, 3)

    override suspend fun getBudgetPlanFactReport(rp: ContractReportPropertiesDto?, contractId: Int) =
        httpSendJsonGetByteArray("contract/$contractId/worth_plan_fact_report", rp, 3)

    override suspend fun getReportToCustomer(
        sert: SvorEntryReportTransactionDto,
        contractId: Int,
    ) = httpSendJsonGetByteArray("contract/$contractId/kc2_report", sert, 10)

    override suspend fun getResourcesExcel(companyId: String) =
        httpSendRequestByteArray("resources_of_company/excel/$companyId", 1)

    override suspend fun getSensorsExcel(companyId: String) =
        httpSendRequestByteArray("sensors/excel/$companyId", 7)

    override suspend fun getDataExcel(
        data: ReportOptionDto,
        contractId: Int,
        companyId: String
    ) = httpSendJsonGetByteArray("data/excel/$companyId/$contractId", data, 3)

    override suspend fun downloadWeightsDistributionExcel(contractId: Int) =
        httpSendJsonGetByteArray<String>("download_weights_distribution/$contractId", null, 2)

    override suspend fun getPicketsReport(contract: ContractDto) = contract.run {
        httpSendRequestByteArray("$contractId/picketsReport", 3)
    }

    override suspend fun getContractsProgressReport(contractsIds: List<Int>, type: Int) =
        httpSendJsonGetByteArray(
            "progress_report_for_contracts/$type",
            contractsIds,
            10
        )
}