import androidx.compose.foundation.layout.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import ru.novasoft.roads.compose_client.core.ui.menu.MenuLogo

data class MenuItem(
    val imageVector: @Composable (MutableState<Boolean>) -> ImageVector,
    val label: String,
    val selectable: Boolean,
    val action: () -> Unit,
)

@Composable
fun MenuItemsList(
    topItems: List<MenuItem>,
    bottomItems: List<MenuItem>,
    expanded: MutableState<Boolean>,
    mapper: @Composable (MenuItem) -> Unit
) {

    Column(
        modifier = Modifier
            .fillMaxSize(),
        verticalArrangement = Arrangement.SpaceBetween,
    ) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.Start,
        ) {
            MenuLogo(expanded)
            Spacer(Modifier.height((MENU_ITEM_HEIGHT * 3).dp))
            topItems.forEach { item ->
                mapper(item)
            }
        }

        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            bottomItems.forEach { item ->
                mapper(item)
            }
        }
    }
}