package novasoft.roads.dto.client_common

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Сущность для отправки клиенту информации по перерасходу ресурсов контракта (как часть ContractProblemsCli)
 */
@Serializable
data class ResourceOverExpenditureTotalDto(
    @SerialName("r")
    val resourceName: String,
    @SerialName("p")
    val planTotal: Double,
    @SerialName("o")
    val factTotal: Double,
    @SerialName("m")
    val onMeters: List<OverExpenditureOnMetersDto>
)