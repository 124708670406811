package novasoft.roads.util

import io.ktor.http.*
import kotlin.math.roundToInt

fun Int.formatBitDepth(): String = FormatUtils.formatBitDepth(this)
fun Long.formatBitDepth(): String = FormatUtils.formatBitDepth(this)
fun Float.formatBitDepth(digits: Int = 3): String = FormatUtils.formatBitDepth(this, digits)
fun Double.formatBitDepth(digits: Int = 3): String = FormatUtils.formatBitDepth(this, digits)

fun String.removeWhiteSpaces() = this.filterNot { it.isWhitespace() }
fun String.fromEditorToDouble() = this.removeWhiteSpaces().replace(',', '.').toDoubleOrNull()
fun String.fromEditorToFloat() = this.removeWhiteSpaces().replace(',', '.').toFloatOrNull()
fun String.fromEditorToLong() = this.removeWhiteSpaces().toLongOrNull()

fun String.fromEditorToLongOrZero(): Long =
    runCatching { this.removeWhiteSpaces().replace(',', '.').toLong() }.getOrElse { 0L }


fun String.fromEditorToShortOrZero(): Short =
    runCatching { this.removeWhiteSpaces().replace(',', '.').toShort() }.getOrElse { 0 }

fun String.toUriEncoded(): String = this.encodeURLQueryComponent()

object FormatUtils {
    fun formatBitDepth(value: String): String {
        return value.reversed().mapIndexed { i, c ->
            if ((i != 0) && (i % 3 == 0)) {
                " $c"
            } else {
                c.toString()
            }
        }.joinToString("").reversed()
    }

    fun formatBitDepth(value: Int): String {
        return formatBitDepth(value.toString())
    }

    fun formatBitDepth(value: Long): String {
        return formatBitDepth(value.toString())
    }

    fun getDividerForAmount(value: Long): Long {
        var leftPart = value
        var divider: Long = 1
        while (leftPart >= 1000 && divider < 10000000000000) {
            divider *= 1000
            leftPart /= 1000
        }
        return divider
    }

    private fun formatRemainingPart(remainingValue: Long, digits: Int): String {
        var remain = remainingValue.toString()
        if (remain.length > digits)
            remain = remain.substring(0..<digits)
        return remain
    }

    fun formatSuffixToAbbr(divider: Long): String {
        return when (divider) {
            1000L -> "тыс"
            1000000L -> "млн"
            1000000000L -> "млрд"
            1000000000000L -> "трлн"
            1000000000000000L -> "тлрд"
            else -> ""
        }
    }

    fun formatToAmountAbbr(value: Long, digits: Int = 3): String {
        val divider = getDividerForAmount(value)

        val leftPart: Long = value / divider
        val remain = formatRemainingPart(value % divider, digits)

        val suffix = formatSuffixToAbbr(divider)
        return "$leftPart,${remain.take(digits)} $suffix"
    }

    fun formatBitDepth(value: Float, digits: Int = 3): String {
        if (digits <= 0)
            return formatBitDepth(value.roundToInt())

        val integer = formatBitDepth(value.toInt())
        val dec = value % 1.0
        if (dec < Double.MIN_VALUE)
            return "$integer,${"0".repeat(digits)}"

        val decString = dec.toString()
        if (decString.contains('.') or decString.contains(",")) {
            val formatted = decString
                .substringAfter(",")
                .substringAfter(".")
                .let { it.substring(0, digits.coerceAtMost(it.lastIndex)) }
                .let { it + "0".repeat((digits - it.length).coerceIn(0, digits)) }
            return "$integer,$formatted"
        }

        throw IllegalArgumentException()
    }

    fun formatBitDepth(value: Double, digits: Int = 3): String {
        if (digits <= 0)
            return formatBitDepth(value.roundToInt())

        val integer = formatBitDepth(value.toInt())
        val dec = value % 1.0
        if (dec < Double.MIN_VALUE)
            return "$integer,${"0".repeat(digits)}"

        val decString = dec.toString()
        if (decString.contains('.') or decString.contains(",")) {
            val formatted = decString
                .substringAfter(",")
                .substringAfter(".")
                .let { it.substring(0, digits.coerceAtMost(it.lastIndex)) }
                .let { it + "0".repeat((digits - it.length).coerceIn(0, digits)) }
            return "$integer,$formatted"
        }

        throw IllegalArgumentException()
    }
}