package ru.novasoft.roads.compose_client.core.network.api.help

import novasoft.roads.dto.client_common.HelpTextForDto
import ru.novasoft.roads.compose_client.core.network.client.ClientMapper
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient

class HelpApi(override val client: RoadsClient) : ClientMapper(client, "help/helptexts"), IHelpApi {

    override suspend fun getHelpText(pageId: String) = makeJsonRequest<String>("page/$pageId")

    override suspend fun saveHelpText(helpTextAssignment: HelpTextForDto) =
        httpSendJsonGetString("save", helpTextAssignment)

    override suspend fun getAllHelpTexts() = makeJsonRequest<List<HelpTextForDto>>("list")

    override suspend fun deleteHelpText(helpId: String) = httpSendRequestString("$helpId/delete")
}