package ru.novasoft.roads.compose_client.core.network.api.company

import io.ktor.utils.io.errors.*
import novasoft.roads.dto.client_common.*
import novasoft.roads.dto.company_team.DepartmentDto
import novasoft.roads.dto.company_team.MasterDto
import novasoft.roads.dto.company_team.RightLevel
import novasoft.roads.dto.company_team.person.PersonDto
import novasoft.roads.dto.company_team.person.PersonsForDepartmentDto
import novasoft.roads.dto.company_team.role.*
import novasoft.roads.dto.company_team.subcontract.SubContractorDto
import novasoft.roads.dto.svor.SvorMeterPartsDto
import novasoft.roads.dto.svor.WorkTypeSimpleDto
import novasoft.roads.dto.usage_calculator.GeofenceDistributionDto
import novasoft.roads.dto.usage_calculator.GeofenceDto
import ru.novasoft.roads.compose_client.core.model.dto.Response
import ru.novasoft.roads.compose_client.core.network.client.ClientMapper
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient

class CompanyApi(override val client: RoadsClient) : ClientMapper(client, "company"), ICompanyApi {
    override suspend fun getMasters(companyId: String) =
        makeJsonRequest<List<MasterDto>>("$companyId/masters")

    override suspend fun getSSbyCompany(companyId: String) =
        makeJsonRequest<Array<SensorDto>>("$companyId/sensors")

    override suspend fun getGeofences(companyId: String) =
        makeJsonRequest<List<GeofenceDto>>("$companyId/geofences")

    /**
     * @return Пустую строку, если ошибок нет. Иначе - сообщение об ошибке.
     * */
    override suspend fun validateGeofence(geofence: GeofenceDto, companyId: String): Response<String> =
        httpSendJsonGetString("$companyId/validate_geofence", geofence)

    override suspend fun saveGeofence(geofence: GeofenceDto, companyId: String): Response<String> =
        httpSendJsonGetString("$companyId/save_geofence", geofence)

    override suspend fun getWeightsDistributionForCompany(companyId: String) =
        makeJsonRequest<GeofenceDistributionDto>("$companyId/company_weights_distribution")

    override suspend fun getGeofencesLoadingsExcelForCompany(
        props: WeightSheetGeneratorPropsDto,
        companyId: String,
    ) = httpSendJsonGetByteArray(
        "$companyId/get_geofences_loadings_excel",
        props,
        1
    )

    override suspend fun getGeofencesLoadingsExcelForGeofences(
        props: WeightSheetGeneratorPropsDto,
        companyId: String,
    ) = httpSendJsonGetByteArray(
        "$companyId/get_geofences_loadings_excel_by_geo_ids",
        props,
        1
    )

    override suspend fun saveContract(
        contract: ContractDto?,
        folderId: Long,
        companyId: String,
    ) = httpSendJsonGetString("$companyId/save/contract/folder/$folderId", contract)

    /**
     * @return Мапа: ключ - id ресурса, значение - список видов работ, где ресурс используется.
     */
    override suspend fun getWorkTypesByResources(companyId: String) =
        makeJsonRequest<Map<Long, List<WorkTypeSimpleDto>>>("$companyId/worktype_by_resources/list")

    override suspend fun getAllModules(companyId: String) =
        makeJsonRequest<Array<ModuleDto>>("$companyId/module_names/list")

    override suspend fun getAllAccesses(companyId: String) =
        makeJsonRequest<Array<AccessDto>>("$companyId/accesses/list")

    override suspend fun getCompanyRoles(companyId: String) =
        makeJsonRequest<Array<RoleDto>>("$companyId/roles/list")

    override suspend fun getCompanyDepartments(companyId: String) =
        makeJsonRequest<Array<DepartmentDto>>("$companyId/departments/list")

    override suspend fun getCompanyWorkers(companyId: String) =
        makeJsonRequest<Array<PersonDto>>("$companyId/workers/list")

    override suspend fun deleteWorker(
        personId: Int,
        companyId: String,
    ) = httpSendRequestString("$companyId/workers/$personId/delete")

    override suspend fun saveWorker(person: PersonDto) = httpSendJsonGetString("${person.companyId}/workers/save", person)

    override suspend fun saveRole(role: RoleFromDto) = httpSendJsonGetString("${role.companyId}/roles/save", role)

    override suspend fun saveDepartment(department: DepartmentDto) =
        httpSendJsonGetString("${department.companyId}/departments/save", department)

    override suspend fun deleteDepartment(
        departmentId: Int,
        companyId: String,
    ) = httpSendRequestString("$companyId/departments/$departmentId/delete")

    override suspend fun deleteRole(
        roleId: Int,
        companyId: String,
    ) = httpSendRequestString("$companyId/roles/$roleId/delete")

    override suspend fun setWorkersToDepartment(
        ids: PersonsForDepartmentDto,
        companyId: String,
    ) = httpSendJsonGetString("$companyId/set_workers_to_department", ids)

    override suspend fun setRoleToWorkersAndDepartments(
        ids: PersonsAndDepartmentsForRolesDto,
        companyId: String,
    ) = httpSendJsonGetString("$companyId/set_role_to_workers_and_department", ids)

    override suspend fun setModuleToRole(
        companyId: String,
        moduleName: String,
        pairs: Set<RoleRightDto>,
    ) = httpSendJsonGetString("$companyId/set_module_to_role/$moduleName", pairs)

    override suspend fun getWorkersAndDepartmentsWithoutOtherRoles(
        roleId: Int,
        companyId: String,
    ) = makeJsonRequest<Pair<String, String>>("$companyId/get_workers_and_departments_without_other_roles/$roleId")

    override suspend fun getRolesRightsForModules(companyId: String) =
        makeJsonRequest<Map<String, List<RoleRightDto>>>("$companyId/get_roles_rights_for_modules")

    override suspend fun getWorkerAccesses(
        personId: Int,
        companyId: String,
    ) = makeJsonRequest<Map<String, RightLevel>>("$companyId/get_worker_accesses/$personId")

    override suspend fun getWorkerInfo(
        personId: Int,
        companyId: String,
    ) = makeJsonRequest<PersonDto>("$companyId/get_worker_info/$personId")

    @Throws(IOException::class)
    override suspend fun getSubcontractorSvorMeterParts(
        contractId: Int,
        meterFrom: Float,
        meterTo: Float,
        svorIds: List<Long>,
        companyId: String,
    ): Response<SvorMeterPartsDto> = httpSendAndGetJson(
        "$companyId/get_subcontractor_svor_meter_parts/contract/$contractId/$meterFrom/$meterTo",
        svorIds
    )

    override suspend fun saveSubContractor(name: String, companyId: String): Response<SubContractorDto> =
        httpSendAndGetJson("$companyId/subcontractors/save", SubContractorDto("", name, companyId))

    override suspend fun getCompanySubContractors(companyId: String) =
        makeJsonRequest<List<SubContractorDto>>("$companyId/subcontractors/list")

    override suspend fun getContractSubContractors(
        contractId: Int,
        companyId: String,
    ) = makeJsonRequest<List<SubContractorDto>>("$companyId/subcontractors_by_contract/contract/$contractId/list")

    override suspend fun getResUnitsConversions(companyId: String) =
        makeJsonRequest<List<ResourceUnitsConversionDto>>("$companyId/res_units_conversions/list")

    override suspend fun getTableColors(companyId: String, tableId: String) =
        makeJsonRequest<List<TableColoringDto>>("$companyId/get_table_colors/$tableId")

    override suspend fun saveTableColoring(
        tableId: String,
        colors: List<TableColoringDto>,
        companyId: String,
    ) = httpSendJsonGetString("$companyId/save_coloring/$tableId", colors)

    /**
     * Параметр companyId нужен из-за того, т.к. это метод контроллера company
     */
    override suspend fun getObjectTypes(companyId: String) =
        makeJsonRequest<List<ObjectTypeDto>>("$companyId/objecttypes")
}