package ru.novasoft.roads.compose_client.core.ui

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import novasoft_roads.core.ui.generated.resources.NS
import novasoft_roads.core.ui.generated.resources.Res
import org.jetbrains.compose.resources.painterResource


@Composable
fun NovasoftLogo(modifier: Modifier = Modifier.height(80.dp).width(128.dp), contentScale: ContentScale = ContentScale.Fit) {
        Card(
            modifier = modifier,
            colors = CardDefaults.cardColors(containerColor = MaterialTheme.colorScheme.surfaceContainerLowest)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.Center,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Image(
                    painterResource(Res.drawable.NS),
                    contentDescription = null,
                    contentScale = contentScale,
                )
            }

        }
}