package novasoft.roads.dto.client_common

import kotlinx.serialization.SerialName

/**
 * Сущность для отправки клиенту (перерасход ресурса на отрезке)
 */
@kotlinx.serialization.Serializable
data class OverExpenditureOnMetersDto(
    @SerialName("f")
    val meterFrom: Int,
    @SerialName("t")
    val meterTo: Int,
    @SerialName("l")
    val plan: Double,
    @SerialName("a")
    val fact: Double
)