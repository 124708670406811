package ru.novasoft.roads.compose_client.core.model.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import novasoft.roads.dto.client_common.ContractType

@Serializable
class ContractModel(
    val name: String,
    @Transient
    val customerName: String = "",
    @Transient
    val contractNumber: String = "",
    @Transient
    val type: ContractType = ContractType.NONE,
    @Transient
    val startDate: Long = 0L,
    @Transient
    val archive: Boolean = false,
    val contractId: Int = -1,
    @Transient
    val worth: Double = 0.0,
    @Transient
    val range: RoadRangeModel<Float>? = null,
    // Если у контракта несколько складов, то их названия должны быть разделены символом ";"
    @Transient
    val storagesFrom1C: String = "",
) {
    @Transient
    var svorWorth: Long = 0

    override fun equals(other: Any?): Boolean {
        return (other != null) and if (other is ContractModel) {
            other.contractId == contractId && other.name == name
        } else false
    }

    override fun toString(): String {
        return name
    }

    override fun hashCode(): Int {
        return contractId
    }
}