package src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.jobs

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import novasoft.roads.dto.meter_progress.ProgressDto
import novasoft.roads.dto.svor.SvorEntryDto
import org.kodein.di.compose.rememberDI
import org.kodein.di.instance
import ru.novasoft.roads.compose_client.core.model.model.ContractModel
import ru.novasoft.roads.compose_client.core.ui.widgets.BudgetAndProgress
import ru.novasoft.roads.compose_client.core.ui.widgets.BudgetCardContent
import ru.novasoft.roads.compose_client.core.ui.widgets.BudgetType

@Composable
fun JobsDashBoardTab(
    svorEntries: Array<SvorEntryDto>?,
    contract: ContractModel
) {
    val jobsViewModel: JobsDashBoardViewModel by rememberDI { instance(arg = contract.contractId) }

    val targetEntries: Map<Long, SvorEntryDto>? by remember(svorEntries) { mutableStateOf(svorEntries?.associateBy { it.id }) }

    val wtDoneParts by jobsViewModel.wtDoneParts.collectAsState()
    val picketsData by jobsViewModel.picketsData.collectAsState()
    val prepayment by jobsViewModel.prepayment.collectAsState()
    val totalProgress by jobsViewModel.totalProgress.collectAsState()
    val contractProblems by jobsViewModel.contractProblems.collectAsState()
    val entriesStatus by jobsViewModel.entriesStatus.collectAsState()
    val isTabDataLoaded by jobsViewModel.isTabDataLoaded.collectAsState()

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(15.dp)
    ) {
        Column(modifier = Modifier.fillMaxWidth()) {
            BudgetCardHolder(targetEntries, totalProgress, prepayment, contract, isTabDataLoaded)
        }
    }
}

@Composable
fun BudgetCardHolder(
    targetEntries: Map<Long, SvorEntryDto>?,
    totalProgress: ProgressDto?,
    prepayment: Long?,
    contract: ContractModel,
    isDataLoaded: Boolean
) {
    val (prepaymentCard, setPrepaymentCard) = remember { mutableStateOf<Long?>(null) }
    val (contractWorth, setContractWorth) = remember { mutableStateOf<Long?>(null) }
    val (budgetMap, setBudgetMap) = remember { mutableStateOf<Map<BudgetType, BudgetAndProgress>>(emptyMap()) }

    var calculationsIsDone: Boolean by remember(targetEntries) { mutableStateOf(false) }

    BudgetCardContent(contractWorth, prepaymentCard, budgetMap, calculationsIsDone && isDataLoaded)

    LaunchedEffect(targetEntries, totalProgress) {
        calculationsIsDone = false
        if (totalProgress == null) return@LaunchedEffect

        if (targetEntries != null) {
//            val totalEntriesProgress = ClientHandler.progress.getContractTotalProgressForSvors(
//                contract.contractId,
//                targetEntries.keys
//            ).data.toProgressDto() ?: ProgressDto(0, 0, 0, 0, 0.0, 0.0, 0.0)
            //TODO(удалить это и раскомментить верхнее)
            val totalEntriesProgress = totalProgress
            setContractWorth(null)
            setPrepaymentCard(null)
            setBudgetMap(
                mapOf(
                    BudgetType.SVOR to BudgetAndProgress(
                        targetEntries.values.sumOf { it.worth }.toLong(),
                        1.0
                    ),
                    BudgetType.PLAN to BudgetAndProgress(
                        totalEntriesProgress.gplanned,
                        totalEntriesProgress.gplanPart.coerceIn(0.0, 1.0)
                    ),
                    BudgetType.DONE to BudgetAndProgress(
                        totalEntriesProgress.done,
                        totalEntriesProgress.donePart.coerceIn(0.0, 1.0)
                    ),
                    BudgetType.REPORTED to BudgetAndProgress(
                        totalEntriesProgress.reported,
                        totalEntriesProgress.passedPart.coerceIn(0.0, 1.0)
                    )
                )
            )
        } else {
            setContractWorth(contract.worth.toLong())
            setPrepaymentCard(prepayment)
            // Если неправильно указана стоимость контракта, то рисуем 999%
            val svorToContractWorthRatio =
                (contract.svorWorth.toDouble() / contractWorth!!).let { if (it.isNaN()) 0.0 else it }
                    .coerceIn(0.0, 9.99)
            totalProgress.let { p ->
                setBudgetMap(
                    mapOf(
                        BudgetType.SVOR to BudgetAndProgress(contract.svorWorth, svorToContractWorthRatio),
                        BudgetType.PLAN to BudgetAndProgress(p.gplanned, p.gplanPart.coerceIn(0.0, 1.0)),
                        BudgetType.DONE to BudgetAndProgress(p.done, p.donePart.coerceIn(0.0, 1.0)),
                        BudgetType.REPORTED to BudgetAndProgress(p.reported, p.passedPart.coerceIn(0.0, 1.0))
                    )
                )
            }
        }
    }
    LaunchedEffect(budgetMap) {
        calculationsIsDone = true
    }
}