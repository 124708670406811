package src.novasoft.roads.compose_client.feature.menu.folders

import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import ru.novasoft.roads.compose_client.core.ui.menu.MenuPageTemplate
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderView

@Composable
fun FoldersView() {
    MenuPageTemplate(
        pageName = "Справочники",
        pageHeader = { PageHeaderView(it) } ,
        pageContent = { Text(text = "Folders view") }
    )
}