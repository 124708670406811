package ru.novasoft.roads.compose_client.core.ui.dialog

import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import novasoft.roads.util.DateUtils

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun DatePickerModal(
    initialValue: Long? = DateUtils.nowUTCMS(),
    onDateSelected: (Long?) -> Unit,
    onDismiss: () -> Unit,
) {
    val datePickerState = rememberDatePickerState(initialValue)

    DatePickerDialog(
        onDismissRequest = onDismiss,
        confirmButton = {
            TextButton(onClick = {
                onDateSelected(datePickerState.selectedDateMillis)
                onDismiss()
            }) {
                Text("Ок")
            }
        },
        dismissButton = {
            TextButton(onClick = onDismiss) {
                Text("Отмена")
            }
        }
    ) {
        DatePicker(state = datePickerState)
    }
}