package ru.novasoft.roads.compose_client.core.network.api.folders

import novasoft.roads.dto.client_common.*
import novasoft.roads.dto.entities_bundle.MeasuringUnitDto
import novasoft.roads.dto.entities_bundle.VariableDto
import novasoft.roads.dto.machinary_fact.TaskTypeDto
import novasoft.roads.dto.reports.RequestString
import novasoft.roads.dto.resource.ResourceDto
import novasoft.roads.dto.resource.ResourceRuleDto
import novasoft.roads.dto.resource.SimpleResourceDto
import ru.novasoft.roads.compose_client.core.model.model.AlterResource
import ru.novasoft.roads.compose_client.core.network.client.ClientMapper
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient
import ru.novasoft.roads.compose_client.core.network.client.wrapper.AsyncJsonRequestWrapper

class FoldersApi(override val client: RoadsClient) : ClientMapper(client, "folders"), IFoldersApi {

    override suspend fun getCompanyTaskTypes(companyId: String) =
        makeJsonRequest<List<TaskTypeDto>>("company/$companyId/task_type/list")

    override suspend fun getResourcesIdNames(companyId: String) =
        makeJsonRequest<List<IdWithNameDto>>("company/$companyId/resources_ids_names")

    override suspend fun getCompanyResources(companyId: String) =
        makeJsonRequest<List<ResourceDto>>("company/$companyId/resources/list")

    override suspend fun getCompanyFolders(companyId: String) =
        makeJsonRequest<List<FolderDto>>("company/$companyId/folders/list")

    override suspend fun getCompanyResourceKits(companyId: String) =
        makeJsonRequest<List<ResourceKitDto>>("company/$companyId/resource_kits/list")

    override suspend fun saveResourceKit(resourceKit: ResourceKitDto, companyId: String) =
        httpSendJsonGetString("company/$companyId/resource_kits/save", resourceKit)

    override suspend fun createNewFolder(folder: FolderDto, companyId: String) =
        httpSendJsonGetString("company/$companyId/folders/create", folder)

    override suspend fun moveContract(
        folderFromId: Long,
        folderToId: Long,
        contractId: Int,
        companyId: String,
    ) = httpSendRequestString("company/$companyId/folders/move/$folderFromId/$folderToId/$contractId")

    override suspend fun moveFolder(
        folderId: Long,
        newParentId: Long,
        companyId: String,
    ) = httpSendRequestString("company/$companyId/folders/move/$folderId/$newParentId")


    override suspend fun deleteFolder(folderId: Long, companyId: String) =
        httpSendRequest("company/$companyId/folders/$folderId/delete")

    override suspend fun renameFolder(
        folderId: Long,
        newName: String,
        companyId: String,
    ) = httpSendJsonGetString("company/$companyId/folders/update/$folderId", RequestString(newName))

    override suspend fun deleteResourceKit(resourceKitId: Long, companyId: String) =
        httpSendRequestString("company/$companyId/resource_kits/$resourceKitId/delete")

    //TODO("решить как будем прокидывать companyId")
    override suspend fun resourceTypes(): AsyncJsonRequestWrapper<List<ResourceTypeDto>> =
        makeJsonRequest<List<ResourceTypeDto>>(""/*"company/${AppWindowController.companyId}/resource_types"*/)

    override suspend fun saveResourceTypes(updatedItems: List<ResourceTypeDto>, companyId: String) =
        httpSendJsonGetString("company/$companyId/save/resource_types", updatedItems)

    override suspend fun deleteResourceType(resourceTypeId: Int, companyId: String) =
        httpSendRequestString("company/$companyId/delete/resource_type/$resourceTypeId")

    override suspend fun units(): AsyncJsonRequestWrapper<List<MeasuringUnitDto>> =
        makeJsonRequest<List<MeasuringUnitDto>>("units")

    override suspend fun variables(): AsyncJsonRequestWrapper<List<VariableDto>> =
        makeJsonRequest<List<VariableDto>>("variables")


    override suspend fun fetchResourcesByWorkTypeId(workTypeId: Long, contractId: Int) =
        makeJsonRequest<List<IdWithNameDto>>("contract/$contractId/resources_of_worktype/$workTypeId")

    override suspend fun getAltResources(companyId: String) =
        makeJsonRequest<List<AlterDto>>("company/$companyId/alt_resources/list")

    override suspend fun saveAltResources(alt: AlterResource) = alt.run {
        httpSendRequestString(
            "save_alt_resources_for_contract/${contract.contractId}/${target.resourceId}/${alter.resourceId}"
        )
    }

    override suspend fun deleteAltResources(alt: AlterResource) = alt.run {
        httpSendRequestString(
            "delete_alt_resources_of_contract/${contract.contractId}/${target.resourceId}/${alter.resourceId}"
        )
    }

    override suspend fun saveSimpleResource(resource: SimpleResourceDto, companyId: String) =
        httpSendJsonGetString("company/$companyId/resource/save", resource)

    override suspend fun saveResources(data: List<ResourceDto>, companyId: String) =
        httpSendJsonGetString("company/$companyId/resources/save", data)

    override suspend fun saveResourceRules(resourceId: Long, rules: Collection<ResourceRuleDto>, companyId: String) =
        httpSendJsonGetString("company/$companyId/resource/$resourceId/rules/save", rules)

    override suspend fun saveResourcesCost(
        type: CostType,
        data: Map<Long, Float>,
        companyId: String,
    ) = httpSendJsonGetString("company/$companyId/resources_cost/$type/save", data)

    override suspend fun getResourcesByEntries(contractId: Int) =
        makeJsonRequest<Map<Long, List<Long>>>("contract/$contractId/resource_by_entries/list")

    override suspend fun getResourcesCost(type: CostType, companyId: String) =
        makeJsonRequest<Map<Long, ResourceCostDto>>("company/$companyId/resources_cost/$type")

    override suspend fun getResourcesIdsByContracts(companyId: String) =
        makeJsonRequest<Map<Int, Set<Long>>>("company/$companyId/resources_ids_by_contracts")

    override suspend fun exchangeResources(exchanges: Map<Long, Long>, companyId: String) =
        httpSendJsonGetString("company/$companyId/exchange_resources", exchanges)

    override suspend fun removeUnusedResources(companyId: String) =
        httpSendRequestString("company/$companyId/unused_resources/remove")
}