package ru.novasoft.roads.compose_client.core.data

import novasoft.roads.dto.company_team.person.PersonInfoDto

class UserRepo: IUserRepo {
    private var dto: PersonInfoDto = PersonInfoDto()

    override fun getUser(): PersonInfoDto {
        return dto
    }

    override fun setUser(userDto: PersonInfoDto) {
       dto = userDto
    }
}