package ru.novasoft.roads.compose_client.core.network.client.wrapper

import io.ktor.client.statement.*
import io.ktor.client.call.*
import io.ktor.http.*
import ru.novasoft.roads.compose_client.core.model.dto.Code
import ru.novasoft.roads.compose_client.core.model.dto.Response


/**
 * Обёртка над ответом HttpClient, возвращающая Response сущность.
 */
data class AsyncJsonRequestWrapper<T>(
    private val response: HttpResponse,
    private val action: (String) -> Response<T>,
) {
    private var success: (T) -> Unit = {}

    fun onSuccess(action: (T) -> Unit): AsyncJsonRequestWrapper<T> {
        success = action
        return this
    }

    private var failure: (code: Code, message: String) -> Unit = { code, message ->
        println("Request:<${response.request.url}> failed with CODE: $code. Details: $message")
        throw RuntimeException()
    }

    fun onFailure(action: (Code, String) -> Unit): AsyncJsonRequestWrapper<T> {
        failure = action
        return this
    }

    private var default: (T?, Code, String) -> Unit = { _, _, _ -> }

    fun onDefault(action: (T?, Code, String) -> Unit): AsyncJsonRequestWrapper<T> {
        default = action
        return this
    }

    suspend fun await(): T {
        val result: Response<T> = if (response.status == HttpStatusCode.OK) {
            extractResponse(response)
        } else {
            Response.bad(response.request.url.toString())
        }

        result.onDefault { data, code, message -> default(data, code, message) }
            .onSuccess { data -> success(data) }
            .onFailure { code, message -> failure(code, message) }

        return result.data
    }

    private suspend fun extractResponse(httpResponse: HttpResponse): Response<T> {
        val body: String = httpResponse.body()
        val path = httpResponse.request.url.toString()

        val bodySizeKB = (body.length * 2) / 1024

        println("Waiting request: $path...")

        if (bodySizeKB > 200) {
            println("WARNING: Request <$path> JSON size is too big: $bodySizeKB kb")
        }

        return action(body)
    }
}
