package ru.novasoft.roads.compose_client.core.network.api.contract_docs

import novasoft.roads.dto.client_common.ContractCalendarPlanCombinedDto
import novasoft.roads.dto.resource.ResourceExpenseDto
import novasoft.roads.dto.svor.PlanV2Dto
import novasoft.roads.dto.svor.SvorEntryCompareVersionsDto
import novasoft.roads.dto.svor.SvorEntryDto
import novasoft.roads.dto.svor.VersionDto
import ru.novasoft.roads.compose_client.core.network.client.ClientMapper
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient

class ContractDocsApi(override val client: RoadsClient) : ClientMapper(client, "docs/contract"), IContractDocsApi {

    override suspend fun getVersionsForPlanUpdate(contractId: Int, isExtend: Boolean) =
        makeJsonRequest<List<VersionDto>>("$contractId/get_versions_for_plan_update/$isExtend")

    override suspend fun getSVOREntriesByVersion(contractId: Int, svorVersion: Int) =
        makeJsonRequest<Array<SvorEntryDto>>("$contractId/svor/$svorVersion")

    override suspend fun getPlansByContract(contractId: Int) = makeJsonRequest<Array<PlanV2Dto>>("$contractId/ppp")

//    override suspend fun updateSvorIDs(
//        contractId: Int,
//        document: Path?,
//        sheetId: Int,
//    ) = httpPostOctetStream("$contractId/update/svor_ids/$sheetId", document, 3)

//    override suspend fun updateSvorPositions(
//        contractId: Int,
//        document: Path?,
//        sheetId: Int,
//    ) = httpPostOctetStream("$contractId/update/svor_positions/$sheetId", document, 3)

//    override suspend fun updateSvorBooleanValues(
//        contractId: Int,
//        document: Path?,
//        sheetId: Int,
//        fieldType: SvorBooleanValueType
//    ) = httpPostOctetStream("$contractId/update/svor_boolean_values/$sheetId/$fieldType", document, 3)

//    override suspend fun updateSvor(
//        contractId: Int,
//        file: Path?,
//        sheetNum: Int,
//        isSigned: Boolean,
//        needToActualizePlans: Boolean,
//    ) = httpPostOctetStream("$contractId/update/svor/$sheetNum/$isSigned/$needToActualizePlans", file!!)

//    override suspend fun patchSvor(
//        contractId: Int,
//        file: Path?,
//        sheetNum: Int,
//        isSigned: Boolean,
//        needToActualizePlans: Boolean,
//    ) = httpPostOctetStream("$contractId/patch/svor/$sheetNum/$isSigned", file!!)

//    override suspend fun updatePicketPlans(
//        contractId: Int,
//        targetVersion: Int,
//        excelFile: Path?,
//        planSheetId: Int,
//        isSigned: Boolean,
//    ) = httpPostOctetStream("$contractId/update/ppp/$targetVersion/$planSheetId/$isSigned", excelFile, 3)

//    override suspend fun extendPicketPlans(
//        contractId: Int,
//        excelFile: Path?,
//        planSheetId: Int,
//        isSigned: Boolean,
//    ) = httpPostOctetStream("$contractId/extend_ppp/$planSheetId/$isSigned", excelFile, 3)

    override suspend fun getCurrentSVORVersions(contractId: Int) =
        makeJsonRequest<List<VersionDto>>("$contractId/get_svor_versions")

    override suspend fun getSvorCompareVersions(contractId: Int, versionOne: Int, versionTwo: Int) =
        makeJsonRequest<List<SvorEntryCompareVersionsDto>>("$contractId/compare_versions/$versionOne/$versionTwo")

    override suspend fun getContractCalendarPlansCombined(contractId: Int) =
        makeJsonRequest<List<ContractCalendarPlanCombinedDto>>("$contractId/get_contract_calendar_plans_combined")

//    override suspend fun loadContractCalendarPlans(document: Path?, contractId: Int, version: Int, sheetNum: Int, extension: String) =
//        httpPostOctetStream("$contractId/$version/$sheetNum/upload_contract_calendar_plans/$extension", document)

    override suspend fun getResourceExpense(contractId: Int) =
        makeJsonRequest<List<ResourceExpenseDto>>("$contractId/get_resource_expense")

//    override suspend fun loadResourceExpense(document: Path?, contractId: Int, sheetNum: Int, extension: String) =
//        httpPostOctetStream("$contractId/$sheetNum/load_resource_expense/$extension", document)

    override suspend fun deleteResourceExpenses(contractId: Int, resExpIds: Set<Long>) =
        httpSendJsonGetString("$contractId/delete_resource_expenses", resExpIds)
}