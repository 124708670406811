package ru.novasoft.roads.compose_client.core.ui.editors

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier

@Composable
fun CommonDialogTextField(
    text: String,
    label: String,
    readOnly: Boolean = false,
    onValueChange: (String) -> Unit = {},
    validation: EditorValidation,
    trailingIcon: @Composable () -> Unit = {},
) {
    CommonDialogTextField(
        text, label, readOnly, onValueChange, listOf(validation), trailingIcon
    )
}

@Composable
fun CommonDialogTextField(
    text: String,
    label: String,
    readOnly: Boolean = false,
    onValueChange: (String) -> Unit = {},
    validation: List<EditorValidation> = emptyList(),
    trailingIcon: @Composable () -> Unit = {},
) {
    val validate = { s: String? -> validation.validateAll(s) }
    ValidationWrapper(text, validate) { errText, isError ->
        TextField(
            modifier = Modifier.fillMaxWidth(),
            label = { Text(text = label) },
            singleLine = true,
            readOnly = readOnly,
            value = text,
            onValueChange = {
                errText.value = validate(it)
                isError.value = errText.value != null
                onValueChange(it)
            },
            isError = isError.value,
            textStyle = MaterialTheme.typography.bodyLarge,
            colors = TextFieldDefaults.colors(
                unfocusedContainerColor = MaterialTheme.colorScheme.surfaceContainerLow,
                focusedContainerColor = MaterialTheme.colorScheme.surfaceContainerLowest
            ),
            trailingIcon = trailingIcon,
            supportingText = { SupportingText(errText) }
        )

    }

}
