package src.novasoft.roads.compose_client.feature.auth

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Visibility
import androidx.compose.material.icons.filled.VisibilityOff
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.paint
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import novasoft_roads.feature.auth.generated.resources.Res
import novasoft_roads.feature.auth.generated.resources.authBackground
import org.jetbrains.compose.resources.painterResource
import org.kodein.di.compose.localDI
import org.kodein.di.compose.rememberDI
import org.kodein.di.compose.subDI
import org.kodein.di.instance
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.defaultPadding
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.defaultSpacing
import ru.novasoft.roads.compose_client.core.ui.NovasoftLogo

@Composable
fun AuthView(
    onSuccessAuth: () -> Unit
) {
    subDI(
        parentDI = localDI(),
        diBuilder = { import(authDIModule()) }
    ) {
        val authViewModel: AuthViewModel by rememberDI { instance() }
        var passwordVisibility by rememberSaveable { mutableStateOf(false) }

        Box(
            modifier = Modifier.fillMaxSize()
                .paint(painter = painterResource(Res.drawable.authBackground), contentScale = ContentScale.FillBounds),
            contentAlignment = Alignment.Center
        ) {
            Card(
                modifier = Modifier.size(width = 544.dp, height = 340.dp),
                shape = RectangleShape,
            ) {
                HorizontalDivider(thickness = 3.dp, color = MaterialTheme.colorScheme.primaryContainer)
                Column(
                    modifier = Modifier
                        .padding(defaultPadding),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.spacedBy(defaultSpacing, Alignment.Bottom)
                ) {
                    NovasoftLogo()
                    TextField(
                        modifier = Modifier.height(60.dp).fillMaxWidth(),
                        label = { Text(text = "Имя пользователя") },
                        singleLine = true,
                        value = authViewModel.dataState.login,
                        onValueChange = { authViewModel.onEvent(AuthUiEvent.LoginChanged(it)) },
                        textStyle = MaterialTheme.typography.bodyLarge,
                        colors = TextFieldDefaults.colors(unfocusedContainerColor = MaterialTheme.colorScheme.surfaceContainerLow, focusedContainerColor = MaterialTheme.colorScheme.surfaceContainerLowest)
                    )
                    TextField(
                        modifier = Modifier.height(60.dp).fillMaxWidth(),
                        label = { Text(text = "Пароль") },
                        singleLine = true,
                        value = authViewModel.dataState.password,
                        onValueChange = { authViewModel.onEvent(AuthUiEvent.PasswordChanged(it)) },
                        textStyle = MaterialTheme.typography.bodyLarge,
                        visualTransformation = if (passwordVisibility) VisualTransformation.None else PasswordVisualTransformation(),
                        keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Password),
                        trailingIcon = {
                            val image = if (passwordVisibility) Icons.Filled.Visibility else Icons.Filled.VisibilityOff
                            val description = if (passwordVisibility) "Показать пароль" else "Скрыть пароль"
                            IconButton(onClick = { passwordVisibility = !passwordVisibility }) {
                                Icon(imageVector = image, description)
                            }
                        },
                        colors = TextFieldDefaults.colors(unfocusedContainerColor = MaterialTheme.colorScheme.surfaceContainerLow, focusedContainerColor = MaterialTheme.colorScheme.surfaceContainerLowest)

                    )

                    TextButton(
                        modifier = Modifier.height(50.dp).fillMaxWidth(),
                        onClick = { authViewModel.onEvent(AuthUiEvent.OkButtonPressed) },
                        shape = RectangleShape,
                        colors = ButtonDefaults.textButtonColors(
                            containerColor = MaterialTheme.colorScheme.primaryContainer,
                            contentColor = MaterialTheme.colorScheme.onPrimaryContainer
                        )
                    ) {
                        Text("Войти", style = MaterialTheme.typography.bodyLarge)
                    }
                }


            }
        }

        LaunchedEffect(authViewModel) {
            authViewModel.events.collect { event ->

                when (event) {
                    is AuthUiState.SuccessSignIn -> {
                        onSuccessAuth()
                    }

                    is AuthUiState.Failure -> {}
                }
            }
        }
    }
}