package ru.novasoft.roads.compose_client.core.data.remote.impl

import ru.novasoft.roads.compose_client.core.data.remote.ITokenRepository
import ru.novasoft.roads.compose_client.core.storage.SharedPreferences

class TokenRepository: ITokenRepository {
    private val link = "jwt"

    override fun getToken(): String = SharedPreferences[link] ?: ""

    override fun setToken(token: String) {
        SharedPreferences[link] = token
    }
}