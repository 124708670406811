package ru.novasoft.roads.compose_client.core.ui.widgets

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import novasoft.roads.util.FormatUtils.formatToAmountAbbr
import ru.novasoft.roads.compose_client.core.ui.chart.WorthDDChart
import ru.novasoft.roads.compose_client.core.ui.effects.LoadingEffect
import kotlin.math.roundToInt


const val BUDGET_CARD_NAME = "Бюджет"

enum class BudgetType(val typeName: String) {
    SVOR("По СВОР"),
    PLAN("Запланировано"),
    DONE("Выполнено"),
    REPORTED("Сдано")
}

data class BudgetAndProgress(
    val budget: Long, val progress: Int
) {
    constructor(budget: Long, progress: Double) : this(budget, progress.let {
        if (it.isNaN()) 0
        else it.times(100).roundToInt()
    })
}

@Composable
fun BudgetCardContent(
    contractWorth: Long?,
    leftPrepayment: Long?,
    budgets: Map<BudgetType, BudgetAndProgress>,
    isDataLoaded: Boolean
) {
    val subtitle = remember(contractWorth, leftPrepayment) {
        buildString {
            contractWorth?.let {
                append("По договору: ${formatToAmountAbbr(it)} руб.")
            }
            leftPrepayment?.takeIf { it > 0 }?.let {
                append("  Остаток аванса: ${formatToAmountAbbr(it)} руб.")
            }
        }
    }

    Card(
        modifier = Modifier
            .width(600.dp)
            .height(300.dp)
            .clip(RoundedCornerShape(15.dp)),
        colors = CardDefaults.cardColors(
            containerColor = MaterialTheme.colorScheme.surface
        ),
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        )
    ) {
        if (isDataLoaded) {
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(16.dp),
                horizontalAlignment = Alignment.Start
            ) {
                Text(
                    text = BUDGET_CARD_NAME,
                    style = MaterialTheme.typography.titleMedium,
                    textAlign = TextAlign.Left
                )

                Spacer(modifier = Modifier.height(8.dp))

                Text(text = subtitle, style = MaterialTheme.typography.titleSmall)

                Spacer(modifier = Modifier.height(16.dp))

                WorthDDChart(
                    svorABS = budgets[BudgetType.SVOR]?.budget ?: 0.0,
                    planABS = budgets[BudgetType.PLAN]?.budget ?: 0.0,
                    doneABS = budgets[BudgetType.DONE]?.budget ?: 0.0,
                    reportedABS = budgets[BudgetType.REPORTED]?.budget ?: 0.0
                )
            }
        } else LoadingEffect()
    }
}


