package src.novasoft.roads.compose_client.feature.menu.contracts

import androidx.compose.runtime.*
import novasoft.roads.dto.client_common.FolderDto
import ru.novasoft.roads.compose_client.core.ui.dialog.CommonDialog
import ru.novasoft.roads.compose_client.core.ui.editors.CommonDialogTextField
import ru.novasoft.roads.compose_client.core.ui.editors.EditorValidation

@Composable
fun EditFolderDialog(
    editingFolder: FolderDto?,
    onDismissRequest: () -> Unit,
    onRename: (folderId: Long, newName: String) -> Unit
) {
    var textState by remember { mutableStateOf(editingFolder?.name ?: "") }
    var isAllFormValid by remember { mutableStateOf(EditorValidation.EmptinessValidation.validate(textState) == null) }

    CommonDialog(
        title = "Переименовать папку",
        onOk = { onRename(editingFolder?.id ?: -1, textState) },
        onDismissRequest = { onDismissRequest() },
        isValidState = isAllFormValid,
    ) {
        CommonDialogTextField(
            text = textState,
            label = "Название папки",
            onValueChange = {
                textState = it
                isAllFormValid = EditorValidation.EmptinessValidation.validate(textState) == null
            },
            validation = EditorValidation.EmptinessValidation
        )
    }
}