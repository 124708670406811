package ru.novasoft.roads.compose_client.core.network.client

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import ru.novasoft.roads.compose_client.core.model.dto.Response
import ru.novasoft.roads.compose_client.core.model.dto.response
import ru.novasoft.roads.compose_client.core.network.client.wrapper.AsyncJsonRequestWrapper
import ru.novasoft.roads.compose_client.core.storage.SharedPreferences

open class RoadsClient(val httpclient: HttpClient) {
    fun isLocalHost() = SharedPreferences["url"] == "localhost"
    val token: String
        get() = SharedPreferences["jwt"] ?: ""

    suspend inline fun <reified T> makeJsonRequest(path: String) =
        AsyncJsonRequestWrapper(httpGetString(path)) { response<T>(it) }

    suspend fun httpGetString(path: String): HttpResponse = httpclient.get(path) { header("Authorization", token) }

    suspend fun httpSendRequest(path: String, timeoutMillis: Long = 1_000_000): Response<ByteArray> = httpclient.get(path) {
        timeout { requestTimeoutMillis = timeoutMillis }
        header("Authorization", token)
    }.body()

    suspend fun httpSendRequestByteArray(path: String, timeoutMillis: Long = 1_000_000): Response<ByteArray> = try {
        val response = httpclient.get(path) {
            timeout { requestTimeoutMillis = timeoutMillis }
            header("Authorization", token)
        }
        universalCheckForResponse<ByteArray>(response, path)
    } catch (e: Exception) {
        Response.bad(path)
    }

    suspend fun httpSendRequestString(path: String, timeoutMillis: Long = 1_000_000): Response<String> = try {
        val response = httpclient.get(path) {
            timeout { requestTimeoutMillis = timeoutMillis }
            header("Authorization", token)
        }
        universalCheckForResponse<String>(response, path)
    } catch (e: Exception) {
        Response.bad(path)
    }

    suspend inline fun <reified A, reified B> httpSendAndGetJson(
        path: String,
        body: A?,
        timeoutInMinutes: Long = 1,
    ): Response<B> = try {
        val response = httpclient.post(path) {
            timeout { requestTimeoutMillis = timeoutInMinutes * 60 * 1000 }
            header("Content-Type", ContentType.Application.Json)
            header("Authorization", token)
            setBody(body)
        }
        universalCheckForResponse<B>(response, path)
    } catch (e: Exception) {
        Response.bad(path)
    }

    suspend fun httpPostOctetStream(
        path: String,
        document: ByteArray,
        timeoutInMinutes: Long,
    ): Response<String> = try {
        val response = httpclient.post(path) {
            timeout { requestTimeoutMillis = timeoutInMinutes * 60 * 1000 }
            header("Content-Type", ContentType.Application.OctetStream)
            header("Authorization", token)
            setBody(document)
        }
        universalCheckForResponse<String>(response, path)
    } catch (e: Exception) {
        Response.bad(path)
    }

    suspend fun checkConnection(): Boolean = httpclient.get("check").status == HttpStatusCode.OK

    /**
     * Валидатор запросов. По контракту успешный ответ - не null
     * data и код равный 200.
     */
    suspend inline fun <reified T> universalCheckForResponse(request: HttpResponse?, path: String): Response<T> =
        if (request != null && request.status == HttpStatusCode.OK)
            response<T>(request.bodyAsText())
        else
            Response.bad(path)
}