package ru.novasoft.roads.compose_client.core.network.di

import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.plugins.observer.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json
import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.instance
import org.kodein.di.singleton
import ru.novasoft.roads.compose_client.core.network.api.analytic.IAnalyticPageApi
import ru.novasoft.roads.compose_client.core.network.api.analytic.AnalyticPageApi
import ru.novasoft.roads.compose_client.core.network.api.auth.IAuthApi
import ru.novasoft.roads.compose_client.core.network.api.auth.AuthApi
import ru.novasoft.roads.compose_client.core.network.api.cash.ICashApi
import ru.novasoft.roads.compose_client.core.network.api.cash.CashApi
import ru.novasoft.roads.compose_client.core.network.api.company.ICompanyApi
import ru.novasoft.roads.compose_client.core.network.api.company.CompanyApi
import ru.novasoft.roads.compose_client.core.network.api.company_assignment.ICompanyAssignmentApi
import ru.novasoft.roads.compose_client.core.network.api.company_assignment.CompanyAssignmentApi
import ru.novasoft.roads.compose_client.core.network.api.contract.IContractApi
import ru.novasoft.roads.compose_client.core.network.api.contract.ContractApi
import ru.novasoft.roads.compose_client.core.network.api.contract_docs.IContractDocsApi
import ru.novasoft.roads.compose_client.core.network.api.contract_docs.ContractDocsApi
import ru.novasoft.roads.compose_client.core.network.api.folders.IFoldersApi
import ru.novasoft.roads.compose_client.core.network.api.folders.FoldersApi
import ru.novasoft.roads.compose_client.core.network.api.help.IHelpApi
import ru.novasoft.roads.compose_client.core.network.api.help.HelpApi
import ru.novasoft.roads.compose_client.core.network.api.logins.ILoginsApi
import ru.novasoft.roads.compose_client.core.network.api.logins.LoginsApi
import ru.novasoft.roads.compose_client.core.network.api.phones.IPhonesApi
import ru.novasoft.roads.compose_client.core.network.api.phones.PhonesApi
import ru.novasoft.roads.compose_client.core.network.api.progress.IProgressApi
import ru.novasoft.roads.compose_client.core.network.api.progress.ProgressApi
import ru.novasoft.roads.compose_client.core.network.api.report.IReportApi
import ru.novasoft.roads.compose_client.core.network.api.report.ReportApi
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient
import ru.novasoft.roads.compose_client.core.storage.SharedPreferences
import co.touchlab.kermit.Logger as Log

fun sharedNetworkDI() = DI {
    import(sharedNetworkModule())
}

fun sharedNetworkModule() = DI.Module("NetworkModule") {
    val serverAddress = SharedPreferences["url"] ?: "http://185.133.40.86"
    val serverPort = SharedPreferences["port"]?.toInt() ?: 1598

    bind<HttpClient>() with singleton {
        HttpClient {
            install(DefaultRequest) {
                header("Content-Type", ContentType.Application.Json)

                url {
                    url(serverAddress)
                    port = serverPort
                }
            }

            install(ContentNegotiation) {
                json(Json {
                    ignoreUnknownKeys = true
                    isLenient = true
                    encodeDefaults = true
                    classDiscriminator = "#class"
                })
            }

            install(Logging) {
                logger = object : Logger {
                    override fun log(message: String) {
                        Log.v( "ktor") {message}
                    }
                }
            }

            install(ResponseObserver) {
                onResponse { response ->
                    Log.i("http_response") { response.status.value.toString() }
                }
            }

            install(HttpTimeout)
        }
    }

    bind<RoadsClient>() with singleton { RoadsClient(instance()) }

    bind<IAnalyticPageApi>() with singleton { AnalyticPageApi(instance()) }

    bind<IAuthApi>() with singleton { AuthApi(instance()) }

    bind<ICashApi>() with singleton { CashApi(instance()) }

    bind<ICompanyApi>() with singleton { CompanyApi(instance()) }

    bind<ICompanyAssignmentApi>() with singleton { CompanyAssignmentApi(instance()) }

    bind<IContractApi>() with singleton { ContractApi(instance()) }

    bind<IContractDocsApi>() with singleton { ContractDocsApi(instance()) }

    bind<IFoldersApi>() with singleton { FoldersApi(instance()) }

    bind<IHelpApi>() with singleton { HelpApi(instance()) }

    bind<ILoginsApi>() with singleton { LoginsApi(instance()) }

    bind<IPhonesApi>() with singleton { PhonesApi(instance()) }

    bind<IProgressApi>() with singleton { ProgressApi(instance()) }

    bind<IReportApi>() with singleton { ReportApi(instance()) }
}