package ru.novasoft.roads.compose_client.core.ui.chart

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import ru.novasoft.roads.compose_client.core.designsystem.theme.contrastGreenColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.doneColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.planColor
import ru.novasoft.roads.compose_client.core.designsystem.theme.uiBrightRedColor
import ru.novasoft.roads.compose_client.core.ui.chart.models.Pie
import novasoft.roads.util.FormatUtils.formatToAmountAbbr

@Composable
fun WorthDDChart(
    svorABS: Number = 0.0,
    planABS: Number = 0.0,
    doneABS: Number = 0.0,
    reportedABS: Number = 0.0
) {
    val data: List<Pair<ChartContainer, Int>> =
        DonutPartType.entries.map { type ->
            val d = type.calculateChartData(svorABS.toDouble(), planABS.toDouble(), doneABS.toDouble(), reportedABS.toDouble())
            d to type.diagramOrder
        }.sortedBy { it.second }


    val innerData = data.mapNotNull { it.first.innerChartData }
    val outerData = data.mapNotNull { it.first.outerChartData }

    Row(
        modifier = Modifier
            .fillMaxSize(),
        verticalAlignment = Alignment.CenterVertically
    ) {
        DoubleDonutChart(
            label = formatToAmountAbbr(svorABS.toLong(), 2),
            modifier = Modifier.weight(1f),
            outerData = outerData,
            innerData = innerData,
            outerWidth = 28.dp,
            innerWidth = 28.dp
        )

        Spacer(modifier = Modifier.width(16.dp))

        LegendBox(innerData + outerData, Modifier.fillMaxWidth().weight(1f))
    }
}

@Composable
fun LegendBox(
    data: List<Pie>,
    modifier: Modifier
) {
    Column(
        modifier = modifier
    ) {
        data.forEach { pie ->
            Row(
                modifier = Modifier
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Box(modifier = Modifier.size(10.dp).background(pie.color))
                    Spacer(modifier = Modifier.width(8.dp))
                    Text(
                        text = pie.label ?: "",
                        style = MaterialTheme.typography.labelSmall,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis,
                    )
                }

                Text(
                    text = formatToAmountAbbr(pie.data.toLong(), 1),
                    style = MaterialTheme.typography.labelSmall,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                    modifier = Modifier.align(Alignment.CenterVertically)
                )
            }
        }
    }
}

data class ChartContainer(
    val innerChartData: Pie? = null,
    val outerChartData: Pie? = null,
    val legendVolume: Double = 0.0
)

enum class DonutPartType(val text: String, val diagramOrder: Int) {
    LEFT("Осталось выполнить", 3),
    CAN_REPORT("Можно сдать", 2),
    REPORTED("Сдано", 1),
    DONE("Выполнено", 1),
    PLAN("План", 2),
    SVOR("СВОР", 3);

    @Composable
    private fun getColor(): Color = when (this) {
        DONE -> MaterialTheme.colorScheme.doneColor
        PLAN -> MaterialTheme.colorScheme.planColor
        LEFT -> MaterialTheme.colorScheme.uiBrightRedColor
        REPORTED -> Color.Green
        CAN_REPORT -> MaterialTheme.colorScheme.contrastGreenColor
        SVOR -> Color.Gray
    }

    @Composable
    fun calculateChartData(
        svorABS: Double,
        planABS: Double,
        doneABS: Double,
        reportedABS: Double
    ): ChartContainer = when (this) {
        LEFT -> {
            val leftVolume = svorABS - maxOf(reportedABS, doneABS)
            ChartContainer(outerChartData = Pie(text, leftVolume, getColor()), legendVolume = leftVolume)
        }
        CAN_REPORT -> {
            val canReportChartVolume = (doneABS - reportedABS).coerceAtLeast(0.0)
            ChartContainer(outerChartData = Pie(text, canReportChartVolume, getColor()), legendVolume = canReportChartVolume)
        }
        REPORTED -> ChartContainer(innerChartData = Pie(text, reportedABS, getColor()), legendVolume = reportedABS)
        DONE -> ChartContainer(outerChartData = Pie(text, doneABS, getColor()), legendVolume = doneABS)
        PLAN -> {
            val chartPlanVolume = (planABS - doneABS).coerceAtLeast(0.0)
            ChartContainer(outerChartData = Pie(text, chartPlanVolume, getColor()), legendVolume = planABS)
        }
        SVOR -> {
            val chartSvorVolume = svorABS - maxOf(planABS, doneABS)
            ChartContainer(outerChartData = Pie(text, chartSvorVolume, getColor()), legendVolume = svorABS)
        }
    }
}