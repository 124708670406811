package ru.novasoft.roads.compose_client.core.network.api.progress

import novasoft.roads.dto.meter_progress.PicketProgressCharDataDto
import novasoft.roads.dto.meter_progress.ProgressWithDefaultDto
import novasoft.roads.dto.meter_progress.WorkTypePicketProgressDto
import novasoft.roads.dto.meter_progress.WorkTypeProgressValuesDto
import novasoft.roads.dto.svor.SvorEntryProgressDto
import ru.novasoft.roads.compose_client.core.model.dto.Response
import ru.novasoft.roads.compose_client.core.network.client.ClientMapper
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient

class ProgressApi(override val client: RoadsClient) : ClientMapper(client, "progress"), IProgressApi {
    override suspend fun getSvorReportEntries(contractId: Int) =
        makeJsonRequest<Array<SvorEntryProgressDto>>("$contractId/get_progress_entries")

    override suspend fun getContractTotalProgressForSvors(
        contractId: Int,
        svorIds: Collection<Long>
    ): Response<ProgressWithDefaultDto> =
        httpSendAndGetJson("$contractId/get_contract_total_progress_for_svors", svorIds)

    override suspend fun getProgressTripleForSvorWorkTypes(contractId: Int) =
        makeJsonRequest<List<WorkTypeProgressValuesDto>>("contract/$contractId/get_worktypes_progress_values")

    override suspend fun calculateSvorPicketCoeffs(contractId: Int) =
        makeJsonRequest<Map<Long, Map<Long, Float>>>("contract/$contractId/calculate_svor_picket_coeffs")

    override suspend fun getPicketProgressForWorkType(workTypeId: Long, contractId: Int) =
        makeJsonRequest<PicketProgressCharDataDto>(
            "contract/$contractId/work_type/$workTypeId/picket_progress_for_worktype"
        )

    override suspend fun getPicketProgressStatusForContractWorkTypes(contractId: Int) =
        makeJsonRequest<List<WorkTypePicketProgressDto>>(
            "contract/$contractId/picket_progress_status_for_contract_work_types/list"
        )

    override suspend fun getPicketProgressForSvors(
        contractId: Int,
        svorIds: Collection<Long>
    ): Response<PicketProgressCharDataDto> =
        httpSendAndGetJson("$contractId/get_picket_progress_for_svors", svorIds)

    override suspend fun getContractsProgressReport(contractsIds: List<Int>, type: Int) =
        httpSendJsonGetByteArray("progress_report_for_contracts/$type", contractsIds, 10)
}