package ru.novasoft.roads.compose_client.core.ui.editors

import novasoft.roads.util.fromEditorToDouble

fun Iterable<EditorValidation>.validateAll(string: String?): String? {
    for (validator in this) {
        val error = validator.validate(string)
        if (error != null)
            return error
    }
    return null
}

sealed interface EditorValidation {
    fun validate(string: String?): String?

    data object EmptinessValidation : EditorValidation {
        override fun validate(string: String?): String? {
            return if (string?.isBlank() == true)
                "Поле не может быть пустым"
            else
                null
        }
    }

    data object FormattedNumberValidation : EditorValidation {
        override fun validate(string: String?): String? {
            return if (string?.fromEditorToDouble() == null)
                "Невозможно преобразовать значение в число"
            else
                null
        }
    }

}