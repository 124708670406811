package novasoft.roads.dto.meter_progress

import kotlinx.serialization.Serializable

@Serializable
data class PlanTDnFactDto(
    /** Доля (0..1) от всего объема пункта СВОР, которая должна быть выполнена сегодня исходя из глобального календарного плана*/
    val planTD: Double,
    /** Доля (0..1) от всего объема пункта СВОР, которая выполнена сегодня по отчетам мастеров*/
    val fact: Double,
)