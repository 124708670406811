package novasoft.roads.dto.client_common

import kotlinx.serialization.Serializable
import novasoft.roads.dto.entities_bundle.MeasuringUnitDto

@Serializable
data class ResourceTypeDto(
    val id: Int,
    val name: String,
    val kind: ResourceKind,
    val unit: MeasuringUnitDto?,
    val pfReport: Boolean
)