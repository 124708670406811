package ru.novasoft.roads.compose_client.core.model.model

import kotlin.math.ceil

data class RoadRangeModel<T>(
    var from: T,
    var to: T
) where T: Comparable<T>, T: Number {
    init {
        if (from > to) {
            from = to.also { to = from }
        }
    }

    override fun equals(other: Any?): Boolean {
        other ?: return false
        if (other !is RoadRangeModel<*>)
            return false
        return (other.from == from) && (other.to == to)
    }

    override fun hashCode(): Int {
        var result = from.hashCode()
        result = 31 * result + to.hashCode()
        return result
    }

    override fun toString(): String {
        return "$from - $to"
    }

    fun <R> map(transform: (T) -> R): RoadRangeModel<R> where R: Comparable<R>, R: Number{
        val a = transform(from)
        val b = transform(to)
        val min = if (a < b) a else b
        val max = if (a > b) a else b
        return RoadRangeModel(min, max)
    }

    operator fun contains(other: RoadRangeModel<T>): Boolean {
        return other.from >= this.from && other.to <= this.to
    }

    operator fun contains(position: T): Boolean {
        return position >= this.from && position <= this.to
    }

    fun containsExclusiveTo(position: T): Boolean {
        return position >= this.from && position < this.to
    }

    fun containsExclusive(position: T): Boolean {
        return position > this.from && position < this.to
    }

    companion object {
        /** Участок с началом округлённым вниз и концом округлённым вверх. */
        fun RoadRangeModel<Float>.widen(): RoadRangeModel<Int> {
            return RoadRangeModel(from.toInt(), ceil(to).toInt())
        }
    }
}