@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package novasoft_roads.core.ui.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val NS: DrawableResource by 
      lazy { init_NS() }

  public val NS2: DrawableResource by 
      lazy { init_NS2() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("NS", CommonMainDrawable0.NS)
  map.put("NS2", CommonMainDrawable0.NS2)
}

internal val Res.drawable.NS: DrawableResource
  get() = CommonMainDrawable0.NS

private fun init_NS(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:NS",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/novasoft_roads.core.ui.generated.resources/drawable/NS.png", -1, -1),
    )
)

internal val Res.drawable.NS2: DrawableResource
  get() = CommonMainDrawable0.NS2

private fun init_NS2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:NS2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/novasoft_roads.core.ui.generated.resources/drawable/NS2.png", -1, -1),
    )
)
