package novasoft.roads.dto.plan.res_sheet_history

import kotlinx.serialization.Serializable
import novasoft.roads.dto.analityc.ResourceUsageDto
import novasoft.roads.dto.client_common.ResourceCostWithTypeDto

@Serializable
data class WorkTypeHistoryDto(
    val workTypeName: String,
    val workTypeId: Long,
    val entriesIds: List<Long>,
    val resourceAmounts: List<ResourceUsageDto>,
    val globalPlans: List<GlobalPlanCopyDto>,
    val resourcesCosts: List<ResourceCostWithTypeDto>
)
