import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowLeft
import androidx.compose.material.icons.automirrored.filled.ArrowRight
import androidx.compose.material.icons.automirrored.filled.Help
import androidx.compose.material.icons.automirrored.filled.Logout
import androidx.compose.material.icons.filled.*
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.dp
import androidx.navigation.NavGraphBuilder
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.compose.rememberNavController
import androidx.navigation.navArgument
import kotlinx.coroutines.launch
import ru.novasoft.roads.compose_client.core.designsystem.theme.menu
import ru.novasoft.roads.compose_client.core.model.roating.Menu
import ru.novasoft.roads.compose_client.core.model.roating.RootRoatings
import ru.novasoft.roads.compose_client.core.ui.DefaultSizes.Padding.menuStartPadding
import ru.novasoft.roads.compose_client.core.ui.DefaultToolTip
import src.novasoft.roads.compose_client.feature.menu.companies.CompaniesView
import src.novasoft.roads.compose_client.feature.menu.contacts.ContactsView
import src.novasoft.roads.compose_client.feature.menu.contract_screen.ContractPageView
import src.novasoft.roads.compose_client.feature.menu.contracts.ContractsView
import src.novasoft.roads.compose_client.feature.menu.folders.FoldersView
import src.novasoft.roads.compose_client.feature.menu.help.HelpView
import src.novasoft.roads.compose_client.feature.menu.team.TeamView

private const val contractIdKey = "contractId"
private const val contractNavRoot = "contractScreen"
private const val contractNavRoute = "${contractNavRoot}/{${contractIdKey}}"
private const val COLLAPSED_WIDTH = 48
private const val EXTENDED_WIDTH = COLLAPSED_WIDTH * 5
const val MENU_ITEM_HEIGHT = 40

/**
 * Вью основного одно страничного приложения
 */
@Composable
fun RoadsSPAView(
    rootNavController: NavHostController
) {
    val menuNavController: NavHostController = rememberNavController()
    val widthState = mutableStateOf(COLLAPSED_WIDTH.dp)
    val expandedState = mutableStateOf(false)
    val scope = rememberCoroutineScope()

    val topItems = listOf(
        MenuItem(
            { Icons.Default.AccountBalance },
            "Контрагенты",
            true
        ) { menuNavController.navigate(Menu.Companies.name) },
        MenuItem({ Icons.Default.Dashboard }, "Контракты", true) { menuNavController.navigate(Menu.Contracts.name) },
        MenuItem({ Icons.Default.Folder }, "Справочники", true) { menuNavController.navigate(Menu.Folders.name) },
        MenuItem({ Icons.Default.People }, "Команда", true) { menuNavController.navigate(Menu.Team.name) },
        MenuItem({ Icons.AutoMirrored.Default.Help }, "Помощь", true) { menuNavController.navigate(Menu.Help.name) },
        MenuItem({ Icons.Default.Contacts }, "Контакты", true) { menuNavController.navigate(Menu.Contacts.name) },
    )
    val botItems: List<MenuItem> = listOf(
        MenuItem(imageVector = { expanded ->
            if (expanded.value)
                Icons.AutoMirrored.Filled.ArrowLeft
            else
                Icons.AutoMirrored.Filled.ArrowRight
        }, "", false) {
            scope.launch {
                expandedState.value = !expandedState.value
                widthState.value = if (expandedState.value) EXTENDED_WIDTH.dp else COLLAPSED_WIDTH.dp
            }
        },
        MenuItem({ Icons.Default.ManageAccounts }, "Администратор", false) { /*onManageAccount()*/ },
        MenuItem(
            { Icons.AutoMirrored.Filled.Logout },
            "Выйти",
            false
        ) { rootNavController.navigate(RootRoatings.Auth.name) },
    )
    val selectedState = mutableStateOf(topItems[1])

    PermanentNavigationDrawer(
        drawerContent = {
            PermanentDrawerSheet(
                modifier = Modifier.width(widthState.value),
                drawerShape = RectangleShape,
                drawerContainerColor = MaterialTheme.colorScheme.primaryContainer,
                drawerContentColor = MaterialTheme.colorScheme.onPrimaryContainer,
            ) {
                MenuItemsList(topItems, botItems, expandedState) { item ->
                    CustomNavigationDrawerItem(
                        icon = {
                            DefaultToolTip(text = item.label, condition = item.label.isNotBlank()) {
                                Icon(item.imageVector(expandedState), null, modifier = Modifier.size(20.dp))
                            }
                        },
                        label = if (expandedState.value) {
                            { Text(item.label, style = MaterialTheme.typography.menu) }
                        } else null,
                        selected = item == selectedState.value,
                        onClick = {
                            if (item.selectable)
                                selectedState.value = item
                            item.action()
                        },
                        colors = NavigationDrawerItemDefaults.colors(
                            selectedContainerColor = MaterialTheme.colorScheme.secondaryContainer,
                            unselectedContainerColor = Color.Transparent,
                            selectedIconColor = MaterialTheme.colorScheme.onPrimaryContainer,
                            unselectedIconColor = MaterialTheme.colorScheme.onSecondaryContainer,
                            selectedTextColor = MaterialTheme.colorScheme.onPrimaryContainer,
                            unselectedTextColor = MaterialTheme.colorScheme.onSecondaryContainer,
                        ),
                    )

                }
            }

        },
        content = {
            /**
             * Хост навигации для SPA
             */
            NavHost(
                navController = menuNavController,
                startDestination = Menu.Contracts.name,
                modifier = Modifier.fillMaxSize()
            ) {
                composable(Menu.Companies.name) { CompaniesView() }
                composable(Menu.Contacts.name) { ContactsView() }
                composable(Menu.Contracts.name) {
                    ContractsView(onContractClick = { contract ->
                        menuNavController.navigate("$contractNavRoot/${contract.contractId}")
                    })
                }
                contractPageView()
                composable(Menu.Folders.name) { FoldersView() }
                composable(Menu.Help.name) { HelpView() }
                composable(Menu.Team.name) { TeamView() }
            }
        }
    )
}

@Composable
fun CustomNavigationDrawerItem(
    label: (@Composable () -> Unit)? = null,
    selected: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    icon: (@Composable () -> Unit),
    shape: Shape = RectangleShape,
    colors: NavigationDrawerItemColors = NavigationDrawerItemDefaults.colors(),
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
) {
    Surface(
        selected = selected,
        onClick = onClick,
        modifier = modifier
            .semantics { role = Role.Tab }
            .height(MENU_ITEM_HEIGHT.dp)
            .fillMaxWidth(),
        shape = shape,
        color = colors.containerColor(selected).value,
        interactionSource = interactionSource,
    ) {
        Row(
            Modifier.padding(horizontal = menuStartPadding),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            val iconColor = colors.iconColor(selected).value
            CompositionLocalProvider(LocalContentColor provides iconColor, content = icon)
            if (label != null) Spacer(Modifier.width(12.dp))
            Box(Modifier.weight(1f)) {
                val labelColor = colors.textColor(selected).value
                CompositionLocalProvider(LocalContentColor provides labelColor, content = label ?: {})
            }
        }
    }
}


/** Экран открытого контракта */
fun NavGraphBuilder.contractPageView() {
    composable(
        route = contractNavRoute,
        arguments = listOf(navArgument(contractIdKey) { type = androidx.navigation.NavType.IntType; nullable = false })
    ) { backStackEntry ->
        val contractId = backStackEntry.arguments?.getInt(contractIdKey)
            ?: throw IllegalStateException("Не удалось найти ключ ${contractIdKey} в бэктреке ${backStackEntry.destination.route}")

        ContractPageView(contractId)
    }
}