@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package novasoft_roads.feature.auth.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val authBackground: DrawableResource by 
      lazy { init_authBackground() }

  public val road: DrawableResource by 
      lazy { init_road() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("authBackground", CommonMainDrawable0.authBackground)
  map.put("road", CommonMainDrawable0.road)
}

internal val Res.drawable.authBackground: DrawableResource
  get() = CommonMainDrawable0.authBackground

private fun init_authBackground(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:authBackground",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/novasoft_roads.feature.auth.generated.resources/drawable/authBackground.png", -1, -1),
    )
)

internal val Res.drawable.road: DrawableResource
  get() = CommonMainDrawable0.road

private fun init_road(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:road",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/novasoft_roads.feature.auth.generated.resources/drawable/road.png", -1, -1),
    )
)
