package novasoft.roads.dto.svor

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

// TODO: ROADS-97
@Serializable
open class SvorEntryDto(
    @SerialName("i")
    var id: Long = -1,
    @SerialName("p")
    var position: Int = -1,
    @SerialName("si")
    val svorReportId: String = "",
    @SerialName("n")
    val workName: String = "",
    @SerialName("wa")
    val workAmounts: List<WorkAmountDto> = listOf(),
    @SerialName("m")
    val mainUnit: Int = -1,
    @SerialName("w")
    val worth: Double = 0.0,
    @SerialName("v")
    val currentVersion: Int = -1,
    @SerialName("np")
    val needPlan: Boolean,
    @SerialName("ic")
    val chapter: Boolean,
    @SerialName("nd")
    val withNds: Boolean
)