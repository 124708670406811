package novasoft.roads.dto.meter_progress

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class WorkTypeProgressValuesDto(
    @SerialName("i") val wortTypeId: Long,
    @SerialName("p") val planned: Double,
    @SerialName("d") val done: Double,
    @SerialName("r") val reported: Double
)