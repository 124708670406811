package ru.novasoft.roads.compose_client.core.network.client

import io.ktor.client.statement.*
import ru.novasoft.roads.compose_client.core.model.dto.Response

// TODO(переделать джаваэфиксовский FileChooser на java.awt.FileDialog для jvm и kotlinx.browser.document для JS)
open class ClientMapper(
    open val client: RoadsClient,
    private val mapping: String
) {
    suspend inline fun <reified T> makeJsonRequest(path: String) = client.makeJsonRequest<T>(path.mapped())

    protected suspend inline fun <reified T> httpSendJsonGetByteArray(
        path: String,
        body: T?,
        timeoutInMinutes: Long,
    ) = client.httpSendAndGetJson<T, ByteArray>(
        path.mapped(),
        body,
        timeoutInMinutes
    )

    protected suspend inline fun <reified T> httpSendJsonGetString(path: String, body: T?) =
        client.httpSendAndGetJson<T, String>(path.mapped(), body)

    suspend fun httpGetString(path: String): HttpResponse = client.httpGetString(path.mapped())

    suspend fun httpSendRequest(path: String, timeout: Long = 1_000_000): Response<ByteArray> =
        client.httpSendRequest(path.mapped(), timeout)

    suspend fun httpSendRequestByteArray(path: String, timeout: Long = 1_000_000) =
        client.httpSendRequestByteArray(path.mapped(), timeout)

    suspend fun httpSendRequestString(path: String) = client.httpSendRequestString(path.mapped())

    protected suspend inline fun <reified A, reified B> httpSendAndGetJson(path: String, body: A?) =
        client.httpSendAndGetJson<A, B>(path.mapped(), body)

//    suspend fun httpPostOctetStream(
//        path: String,
//        document: Path?,
//        timeoutInMinutes: Long = 1,
//    ) = client.httpPostOctetStream(
//        path.mapped(),
//        document,
//        timeoutInMinutes
//    )

    fun String.mapped() = "$mapping/$this"

}