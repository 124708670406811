package src.novasoft.roads.compose_client.feature.menu.contracts

import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.instance
import org.kodein.di.singleton

fun contractsDIModule() = DI.Module("contractsModule") {
    val contractsViewModelSingleton = singleton { ContractsViewModel(instance(), instance(), instance(), instance(), instance()) }
    bind<ContractsViewModel>() with contractsViewModelSingleton
}