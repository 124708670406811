package common

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow

/**
 * Cодержит бизнес логику: принимает, обрабатывает и отдает события.
 * Через onEvent последовательно обрабатывает каждое из принятых от View событий
 * а затем через flow делает emit обновленных состояний для View. Также модифицирует сам state, который может хранить, в отличие от View.
 */
abstract class RoadsViewModel {
    val eventChannel = MutableSharedFlow<RoadsAppEvent>()
    val events = eventChannel.asSharedFlow()

    abstract fun onEvent(uiEvent: RoadsUiEvent)

    val viewModelScope = CoroutineScope(Dispatchers.Default)

    fun <T> callAsync(func: suspend () -> T): Deferred<T> {
        return viewModelScope.async {
            func()
        }
    }
}