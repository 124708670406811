package novasoft.roads.dto.svor

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import novasoft.roads.dto.entities_bundle.MeasuringUnitDto

@Serializable
data class WorkAmountDto(
    @SerialName("a")
    val workAmount: Double,
    @SerialName("u")
    val unit: MeasuringUnitDto,
)