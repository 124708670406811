package novasoft.roads.dto.analityc

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ResourceUsageDto(
    @SerialName("i")
    var resourceId: Long,
    @SerialName("a")
    var amount: Float,
    @SerialName("u")
    var unit: Int,
    @SerialName("s")
    var shifts: Float?,
)