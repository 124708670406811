package src.novasoft.roads.compose_client.feature.auth

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import co.touchlab.kermit.Logger
import common.RoadsUiEvent
import common.RoadsViewModel
import kotlinx.coroutines.launch
import ru.novasoft.roads.compose_client.core.data.IUserRepo
import ru.novasoft.roads.compose_client.core.data.remote.IAppStateInfoRepository
import ru.novasoft.roads.compose_client.core.data.remote.IAuthRepository
import ru.novasoft.roads.compose_client.core.data.remote.ITokenRepository

class AuthViewModel(
    private val authRepository: IAuthRepository,
    private val tokenRepository: ITokenRepository,
    private val userRepo: IUserRepo,
    private val appStateInfoRepo: IAppStateInfoRepository
) : RoadsViewModel() {
    var dataState by mutableStateOf(AuthState())

    override fun onEvent(uiEvent: RoadsUiEvent) {
        if (uiEvent !is AuthUiEvent) throw IsNotAuthUiEvent()

        when (uiEvent) {
            is AuthUiEvent.LoginChanged -> dataState = dataState.copy(login = uiEvent.login)
            is AuthUiEvent.PasswordChanged -> dataState = dataState.copy(password = uiEvent.password)

            AuthUiEvent.OkButtonPressed -> viewModelScope.launch {

                dataState.run {
                    authRepository.signIn(login, password)
                        .onSuccess {
                            appStateInfoRepo.setCompanyId(it.companyId)
                            userRepo.setUser(it)
                            tokenRepository.setToken(it.jwtToken)
                            Logger.i("auth") { "Auth token ${tokenRepository.getToken()} " }
                            launch { eventChannel.emit(AuthUiState.SuccessSignIn) }
                        }
                        .onFailure { _, _ ->
                            launch { eventChannel.emit(AuthUiState.Failure) }
                        }
                }
            }
        }
    }
}