package ru.novasoft.roads.compose_client.core.ui.chart

import androidx.compose.foundation.layout.*
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import ru.novasoft.roads.compose_client.core.ui.chart.models.Pie

@Composable
fun DoubleDonutChart(
    label: String = "",
    modifier: Modifier = Modifier,
    outerData: List<Pie>,
    innerData: List<Pie>,
    outerWidth: Dp,
    innerWidth: Dp
) {
    BoxWithConstraints(
        modifier = modifier,
        contentAlignment = Alignment.Center
    ) {
        val outerRadius = minOf(maxWidth, maxHeight) / 2
        val innerRadius = outerRadius - outerWidth

        // Внутренний пончик
        PieChart(
            data = innerData,
            style = Pie.Style.Stroke(width = innerWidth),
            modifier = Modifier.size(innerRadius * 2)
        )

        // Внешний пончик
        PieChart(
            data = outerData,
            style = Pie.Style.Stroke(width = outerWidth),
            modifier = Modifier.size(outerRadius * 2)
        )

        Text(
            text = label,
            modifier = Modifier.width(innerRadius - innerWidth).align(Alignment.Center),
            style = MaterialTheme.typography.labelMedium,
        )
    }
}

//Пример для Preview
@Composable
fun DoubleDonutPieChartExample(label: String = "Double Donut Chart") {
    val outerData = listOf(
            Pie(label = "windows", 30.0, Color.Red),
            Pie(label = "ubu", 20.0, Color.Green),
            Pie(label = "astra", 50.0, Color.Blue)
    )


    val innerData = listOf(
        Pie(label = "ios", 20.0, Color.DarkGray),
        Pie(label = "mac", 50.0, Color.Magenta)
    )

    DoubleDonutChart(
        label = label,
        modifier = Modifier.fillMaxSize(),
        outerData = outerData,
        innerData = innerData,
        outerWidth = 40.dp,
        innerWidth = 40.dp
    )
}
