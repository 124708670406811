package novasoft.roads.dto.entities_bundle

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VariableDto (
    @SerialName("i")
    val id: Int = -1,
    @SerialName("s")
    val sym: String = "",
    @SerialName("n")
    val name: String = "",
    @SerialName("u")
    val unit: Int = -1,
    @SerialName("c")
    val constant: Boolean = true
)