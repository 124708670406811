package ru.novasoft.roads.compose_client.core.network.api.cash

import novasoft.roads.dto.all_cash_progress.*
import ru.novasoft.roads.compose_client.core.network.client.ClientMapper
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient

class CashApi(override val client: RoadsClient) : ClientMapper(client, "cash"), ICashApi {
    override suspend fun saveCashPerPeriod(
        contractId: Int,
        version: Int,
        data: List<CashPeriodUserDataDto>
    ) =
        httpSendJsonGetString("$contractId/save_cash_per_period/$version", data)

    override suspend fun deleteCashPerYear(contractId: Int, version: Int, year: Int)  =
        httpSendRequestString("$contractId/$version/$year/delete_cash_per_year")

    override suspend fun deleteCashPerDate(
        contractId: Int,
        version: Int,
        year: Int,
        month: Int,
        day: Int
    ) = httpSendRequestString("$contractId/$version/$year/$month/$day/delete_cash_per_date")

    override suspend fun getCashTotalProgressForContract(contractId: Int) =
        makeJsonRequest<CashPeriodProgressDto>("$contractId/get_cash_total_progress_for_contract")

    override suspend fun getCashProgressForAllTime(contractId: Int) =
        makeJsonRequest<List<CashPeriodProgressDto>>("$contractId/get_cash_progress_for_all_time")

    override suspend fun getCashVersionSummary(contractId: Int) =
        makeJsonRequest<CashVersionSummaryDto>("$contractId/get_cash_version_summary")

    override suspend fun getCashByVersion(
        contractId: Int,
        version: Int
    ) = makeJsonRequest<List<CashPeriodUserDataDto>>("$contractId/$version/get_cash_by_version")

    override suspend fun getCashConstants(contractId: Int) =
        makeJsonRequest<CashConstantsDto>("$contractId/get_cash_constants")

    override suspend fun editCashConstant(contractId: Int, changesInfo: CashConstantChangeDto) =
        httpSendJsonGetString("$contractId/edit_cash_constant", changesInfo)
}