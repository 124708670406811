package novasoft.roads.dto.meter_progress

import kotlinx.serialization.Serializable

/**
 * Для отображения на дашборде во вкладке "Работы
 */
@Serializable
data class DashBoardSvorEntryStatusDto(
    val entryId: Long,
    val months: Map<Int, PlanTDnFactDto>,
    /** Дата предполагаемого(экстраполируемого) окончания работ в мс*/
    val forecastDateOfFinish: Long,
    /** Дата планового окончания работ в мс*/
    val planDateOfFinish: Long,
)

