package ru.novasoft.roads.compose_client.core.model.mapping

import novasoft.roads.dto.client_common.RoadRangeDto
import ru.novasoft.roads.compose_client.core.model.model.RoadRangeModel

fun <T> toRoadRangeDto(model: RoadRangeModel<T>): RoadRangeDto<T> where T: Comparable<T>, T: Number = RoadRangeDto(
    model.from,
    model.to
)

fun <T> toRoadRangeModel(dto: RoadRangeDto<T>): RoadRangeModel<T> where T: Comparable<T>, T: Number = RoadRangeModel(
    dto.from,
    dto.to
)