package novasoft.roads.dto.svor

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import novasoft.roads.dto.client_common.ResourceOverExpenditureTotalDto

/**
 * Сущность, которая хранит все данные по проблемам контракта для отправки клиенту
 */
@Serializable
class ContractProblemsDto(
    @SerialName("s")
    val svorPlanDiffStatus: SvorPlanDiffStatusDto = SvorPlanDiffStatusDto(),
    @SerialName("d")
    val docDiffErrors: String? = null,
    @SerialName("u")
    val unitsErrors: String? = null,
    @SerialName("w")
    val unresolvedWeights: Int = 0,
    @SerialName("e")
    val resourceOverExpenditure: List<ResourceOverExpenditureTotalDto> = emptyList()
)