package src.novasoft.roads.compose_client.feature.menu.contracts

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.ui.util.fastAny
import common.RoadsUiEvent
import common.RoadsViewModel
import novasoft.roads.dto.client_common.ContractDto
import novasoft.roads.dto.client_common.FolderDto
import novasoft.roads.dto.meter_progress.ProgressDto
import novasoft.roads.dto.meter_progress.ProgressStatus
import co.touchlab.kermit.Logger
import kotlinx.coroutines.*
import ru.novasoft.roads.compose_client.core.data.remote.IAppStateInfoRepository
import ru.novasoft.roads.compose_client.core.model.dto.Response
import ru.novasoft.roads.compose_client.core.network.api.analytic.IAnalyticPageApi
import ru.novasoft.roads.compose_client.core.network.api.company.ICompanyApi
import ru.novasoft.roads.compose_client.core.network.api.contract.IContractApi
import ru.novasoft.roads.compose_client.core.network.api.folders.IFoldersApi
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderContract
import ru.novasoft.roads.compose_client.core.ui.menu.PageHeaderEvent

private const val TEMP = "projectT"

class ContractsViewModel(
    private val analyticPageApi: IAnalyticPageApi,
    private val foldersApi: IFoldersApi,
    private val contractApi: IContractApi,
    private val companyApi: ICompanyApi,
    val appStateInfoRepo: IAppStateInfoRepository
) : RoadsViewModel(), PageHeaderContract {
    var state by mutableStateOf(ContractsState())
    var searchText = mutableStateOf("")

    private val folderIdsHistory = ArrayDeque<Long>()
    private var contracts: Map<Int, ContractDto> = mapOf()
    private var contractsProgress: Map<Int, ProgressDto> = mapOf()
    private var folders: Map<Long, FolderDto> = mapOf()


    private var companyId by mutableStateOf(TEMP)
    private var curFolderScope: FolderDto? = null

    init {
        val calls = listOf(
            viewModelScope.launch {
                contracts = analyticPageApi.getCompanyContracts(companyId).await().associateBy { it.contractId }
            },
            viewModelScope.launch {
                contractsProgress = analyticPageApi.getCompanyContractsProgresses(companyId).await()
            },
            updateFolders(),
        )
        viewModelScope.launch {
            calls.joinAll()
            updateViewState()
        }
    }

    private fun updateFolders(): Job {
        return viewModelScope.launch { folders = foldersApi.getCompanyFolders(companyId).await().associateBy { it.id } }
    }

    fun getContractParams(contractId: Int) =
        state.contractCardParams[contractId] ?: ContractCardParams(0.0, ProgressStatus.PLAN)

    private fun updateViewState() {
        setFolderScope()
        replaceCards()
    }

    override fun onEvent(uiEvent: RoadsUiEvent) {
        if (uiEvent !is ContractsUiEvent) return
        when (uiEvent) {
            is ContractsUiEvent.ContractClicked -> viewModelScope.launch {
                eventChannel.emit(ContractsAppEvent.OpenedContractState(uiEvent.contractDto))
            }

            is ContractsUiEvent.FolderClicked -> openFolder(uiEvent.folderDto)
            is ContractsUiEvent.SomethingDroppedOnFolder -> processDrop(uiEvent.data, uiEvent.targetFolder)

            is ContractsUiEvent.CardBaseAction.ContractCardAction.MoveToUpFolder ->
                ifNotRoot {
                    processUpdate(
                        transferContractToFolder(
                            uiEvent.contractDto, folders[curFolderScope!!.parent!!]!!
                        )
                    )
                }

            is ContractsUiEvent.CardBaseAction.FolderCardAction.MoveToUpFolder ->
                ifNotRoot {
                    processUpdate(
                        transferFolderToFolder(
                            uiEvent.folderDto, folders[curFolderScope!!.parent!!]!!
                        )
                    )
                }

            is ContractsUiEvent.CardBaseAction.ContractCardAction.CopyContract ->
                processUpdate(
                    callAsync {
                        contractApi.copyContract(uiEvent.contractDto.contractId)
                    }
                )

            is ContractsUiEvent.CardBaseAction.ContractCardAction.DeleteContract ->
                processUpdate(
                    callAsync {
                        contractApi.dropContract(uiEvent.contractDto.contractId)
                    }
                )

            is ContractsUiEvent.CardBaseAction.ContractCardAction.EditContractClicked -> viewModelScope.launch {
                eventChannel.emit(ContractsAppEvent.EditContract(uiEvent.contractDto))
            }

            is ContractsUiEvent.CardBaseAction.FolderCardAction.DeleteFolder ->
                processUpdate(
                    callAsync {
                        foldersApi.deleteFolder(uiEvent.folderDto.id, companyId)
                    }
                )

            is ContractsUiEvent.CardBaseAction.FolderCardAction.RenameFolderClicked -> viewModelScope.launch {
                eventChannel.emit(ContractsAppEvent.RenameFolder(uiEvent.folderDto))
            }

            is ContractsUiEvent.RenameFolder ->
                processUpdate(
                    callAsync {
                        foldersApi.renameFolder(uiEvent.folderID, uiEvent.newName, companyId)
                    }
                )

            is ContractsUiEvent.EditContract ->
                processUpdate(
                    callAsync {
                        companyApi.saveContract(uiEvent.newContractDto, curFolderScope!!.id, companyId)
                    }
                )
        }
    }

    private fun ifNotRoot(action: () -> Unit) {
        if (curFolderScope?.parent != null) action()
    }

    private fun openFolder(folderDto: FolderDto) {
        setFolderScope(folderDto)
        replaceCards()
    }

    private fun transformProgress(map: Map<Int, ProgressDto>): Map<Int, ContractCardParams> {
        return map.mapValues { (contractId, p) ->
            val isArchive = contracts[contractId]?.archive ?: return@mapValues ContractCardParams()
            if (isArchive) ContractCardParams(1.0, ProgressStatus.ARCHIVE)
            else getProgressStatus(p)
        }
    }

    private fun getProgressStatus(p: ProgressDto): ContractCardParams {
        val zeroBorder = 0.001
        if (p.donePart < zeroBorder && p.passedPart < zeroBorder) return ContractCardParams(
            p.gplanPart, ProgressStatus.PLAN
        )
        if (p.passedPart > 0.5) return ContractCardParams(p.passedPart, ProgressStatus.REPORTED)
        return ContractCardParams(p.donePart, ProgressStatus.DONE)
    }

    //Устанавливаем текущую папку, в которой находимся, пушим её в историю
    private fun setFolderScope(folderScope: FolderDto = folders.values.first { it.parent == null }) {
        curFolderScope = folderScope

        if (folderIdsHistory.firstOrNull() == curFolderScope!!.id) return

        folderIdsHistory.addFirst(curFolderScope!!.id)
    }

    private fun replaceCards() {
        //Рекурсивно ищет в папке контракты, название которых содержит search.text
        fun hasMatchingContracts(folderId: Long): Boolean {
            val folder = folders[folderId]!!
            folder.contractIds.mapNotNull { contracts[it] }.fastAny { c -> c.name.contains(searchText.value) }
                .let { if (it) return true }

            for (childFolder in folder.children) {
                if (hasMatchingContracts(childFolder)) return true
            }
            return false
        }

        val filteredContracts = if (searchText.value.isBlank()) getCurScopeContracts()
        else getCurScopeContracts().filter { it.name.contains(searchText.value.trim(), true) }

        val filteredFolders = if (searchText.value.isBlank()) getCurScopeFolders()
        else getCurScopeFolders().filter { hasMatchingContracts(it.id) }

        state = state.copy(
            contracts = filteredContracts,
            contractCardParams = transformProgress(contractsProgress),
            folders = filteredFolders
        )
    }

    //Фильтруем, чтобы не сортировать для каждой папки отдельно, т.к. они уже отсортированы текущим компаратором
    private fun getCurScopeContracts() = contracts.filter { it.key in curFolderScope!!.contractIds }.values.toList()

    private fun getCurScopeFolders() = folders.filter { it.key in curFolderScope!!.children }.values.toList()


    private fun back() {
        if (folderIdsHistory.size > 1) {
            folderIdsHistory.removeFirst()
            curFolderScope = folders[folderIdsHistory.first()]
            replaceCards()
        }
    }

    override fun onPageHeaderEvent(event: PageHeaderEvent) {
        when (event) {
            PageHeaderEvent.BackClicked -> back()
            PageHeaderEvent.HelpClicked -> {}
        }
    }

    private fun transferContractToFolder(
        contractDto: ContractDto, targetFolder: FolderDto
    ) = callAsync {
        foldersApi.moveContract(curFolderScope!!.id, targetFolder.id, contractDto.contractId, companyId)
    }


    private fun transferFolderToFolder(folderDto: FolderDto, targetFolder: FolderDto) = callAsync {
        foldersApi.moveFolder(folderDto.id, targetFolder.id, companyId)
    }

    /**
     * Синхронизирует иерархию папок и контракты в них с сервером, при успешном response в аргументе
     * @param transferDeferred Deferred с результатом какого-либо запроса к серверу
     */
    private fun processUpdate(transferDeferred: Deferred<Response<*>>) {
        /** Deferred с Job по обновлению иерархии папок */
        val foldersUpdateDeferred = callAsync {
            var updateFoldersJob: Job? = null
            transferDeferred.await().onSuccess {
                updateFoldersJob = updateFolders() // Здесь будет получен обновленная иерархия папок с бэка
            }
            updateFoldersJob
        }

        viewModelScope.launch {
            foldersUpdateDeferred.await()?.join()
            folders[curFolderScope?.id]?.let { setFolderScope(it) } ?: setFolderScope()
            replaceCards()
        }
    }

    private fun processDrop(data: ContractsDragAndDropData, targetFolder: FolderDto) {
        /** Deferred по переносу контракта/папки между папками */
        val transferDeferred = kotlin.runCatching {
            when {
                data.contract != null -> transferContractToFolder(data.contract, targetFolder)
                data.folder != null -> transferFolderToFolder(data.folder, targetFolder)
                else -> throw IllegalArgumentException("Dropped contract and folder are null")
            }
        }.getOrElse {
            Logger.e("Drop processing failure", it)
            return
        }
        processUpdate(transferDeferred)
    }

    fun getContractCardActions(contractDto: ContractDto): List<ContractsUiEvent.CardBaseAction.ContractCardAction> =
        listOf(
            ContractsUiEvent.CardBaseAction.ContractCardAction.MoveToUpFolder(contractDto),
            ContractsUiEvent.CardBaseAction.ContractCardAction.EditContractClicked(contractDto),
            ContractsUiEvent.CardBaseAction.ContractCardAction.CopyContract(contractDto),
            ContractsUiEvent.CardBaseAction.ContractCardAction.DeleteContract(contractDto),
        )

    fun getFolderCardActions(folderDto: FolderDto): List<ContractsUiEvent.CardBaseAction.FolderCardAction> = listOf(
        ContractsUiEvent.CardBaseAction.FolderCardAction.MoveToUpFolder(folderDto),
        ContractsUiEvent.CardBaseAction.FolderCardAction.RenameFolderClicked(folderDto),
        ContractsUiEvent.CardBaseAction.FolderCardAction.DeleteFolder(folderDto),
    )

    fun getAllCustomers(): List<String> {
        return contracts.values.map { it.customerName }.distinct().filter { it.isNotBlank() }
    }
}