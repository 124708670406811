package ru.novasoft.roads.compose_client.core.network.api.analytic

import novasoft.roads.dto.analityc.TotalAndYTDDto
import novasoft.roads.dto.analityc.WorkTypeFactByPicketsDto
import novasoft.roads.dto.client_common.ContractDto
import novasoft.roads.dto.finances.FinancesDto
import novasoft.roads.dto.finances.FinancialBalanceDto
import novasoft.roads.dto.meter_progress.DashBoardSvorEntryStatusDto
import novasoft.roads.dto.meter_progress.PicketProgressCharDataDto
import novasoft.roads.dto.meter_progress.ProgressDto
import novasoft.roads.dto.reports.work_type_info.WorkTypesAndSvorsInfoDto
import novasoft.roads.dto.svor.ContractProblemsDto
import novasoft.roads.dto.svor.SvorPlanDiffStatusDto
import ru.novasoft.roads.compose_client.core.network.client.ClientMapper
import ru.novasoft.roads.compose_client.core.network.client.RoadsClient

class AnalyticPageApi(override val client: RoadsClient) : ClientMapper(client, "analytic"), IAnalyticPageApi {

    override suspend fun getContractTotalProgress(contractId: Int) =
        makeJsonRequest<ProgressDto>("progress/contract/$contractId/get_contract_total_progress")

    override suspend fun getPicketProgress(contractId: Int) =
        makeJsonRequest<PicketProgressCharDataDto>("progress/contract/$contractId/get_picket_progress")

    override suspend fun getContractWorth(contractId: Int) =
        makeJsonRequest<Long>("contract/$contractId/contract_worth")

    override suspend fun getContractTotalPrepayment(contractId: Int) =
        makeJsonRequest<Long>("contract/$contractId/prepayments/total")

    override suspend fun getSvorPlanDifferenceStatus(contractId: Int) =
        makeJsonRequest<SvorPlanDiffStatusDto>("contract/$contractId/get_svor_plan_difference_status")

    override suspend fun getContractErrorStatus(contractId: Int) =
        makeJsonRequest<ContractProblemsDto>("contract/$contractId/get_contract_error_status")

    override suspend fun getVersionDate(contractId: Int, versionId: Int) =
        makeJsonRequest<Long>("contract/$contractId/version_data/$versionId")

    override suspend fun getWorkTypesAndSvorsInfo(contractId: Int) =
        makeJsonRequest<List<WorkTypesAndSvorsInfoDto>>("contract/$contractId/worktypes_and_svors_info")

    override suspend fun getWorkTypesIdsWithSubContract(contractId: Int) =
        makeJsonRequest<List<Pair<Long, String>>>("contract/$contractId/worktypes_with_subcontract_ids")

    override suspend fun getCompanyContracts(companyId: String) =
        makeJsonRequest<List<ContractDto>>("company/$companyId/contracts")

    override suspend fun getCompanyContractsProgresses(companyId: String) =
        makeJsonRequest<Map<Int, ProgressDto>>("company/$companyId/get_company_contracts_total_progresses")

    override suspend fun getResourcesFactAnalyticData(contractId: Int) =
        makeJsonRequest<Map<Long, Map<Long, TotalAndYTDDto>>>("contract/$contractId/resources_fact_analytic")

    override suspend fun getWorkTypesFactByPickets(contractId: Int) =
        makeJsonRequest<List<WorkTypeFactByPicketsDto>>("contract/$contractId/worktype_fact_by_pickets")

    override suspend fun getContractFinances(contractId: Int) =
        makeJsonRequest<FinancesDto>("contract/$contractId/finances")

    override suspend fun getPicketOrder(contractId: Int) =
        makeJsonRequest<List<Long>>("contract/$contractId/picket_order")

    override suspend fun getSvorEntriesDashBoardStatus(contractId: Int) =
        makeJsonRequest<List<DashBoardSvorEntryStatusDto>>("contract/$contractId/svor_entries_dashboard_status")

    override suspend fun getFactFinancialBalance(contractId: Int) =
        makeJsonRequest<FinancialBalanceDto>("contract/$contractId/fact_financial_balance")
}