package src.novasoft.roads.compose_client.feature.menu.contract_screen.tabs.jobs

import common.RoadsUiEvent
import common.RoadsViewModel
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import novasoft.roads.dto.meter_progress.DashBoardSvorEntryStatusDto
import novasoft.roads.dto.meter_progress.PicketProgressCharDataDto
import novasoft.roads.dto.meter_progress.ProgressDto
import novasoft.roads.dto.meter_progress.WorkTypeProgressValuesDto
import novasoft.roads.dto.svor.ContractProblemsDto
import ru.novasoft.roads.compose_client.core.network.api.analytic.IAnalyticPageApi
import ru.novasoft.roads.compose_client.core.network.api.progress.IProgressApi

class JobsDashBoardViewModel(
    private val progressApi: IProgressApi,
    private val analyticPageApi: IAnalyticPageApi,
    private val contractId: Int
) : RoadsViewModel() {

    private val _isTabDataLoaded = MutableStateFlow(false)
    val isTabDataLoaded = _isTabDataLoaded.asStateFlow()

    private val _wtDoneParts = MutableStateFlow<List<WorkTypeProgressValuesDto>>(emptyList())
    val wtDoneParts = _wtDoneParts.asStateFlow()

    private val _picketsData = MutableStateFlow<PicketProgressCharDataDto?>(null)
    val picketsData = _picketsData.asStateFlow()

    private val _prepayment = MutableStateFlow<Long>(0L)
    val prepayment = _prepayment.asStateFlow()

    private val _totalProgress = MutableStateFlow<ProgressDto?>(null)
    val totalProgress = _totalProgress.asStateFlow()

    private val _contractProblems = MutableStateFlow<ContractProblemsDto>(ContractProblemsDto())
    val contractProblems = _contractProblems.asStateFlow()

    private val _entriesStatus = MutableStateFlow<List<DashBoardSvorEntryStatusDto>>(emptyList())
    val entriesStatus = _entriesStatus.asStateFlow()

    init {
        viewModelScope.launch {
            val jobs = listOf(
                emitWtDoneParts(),
                emitPicketsData(),
                emitPrepayment(),
                emitTotalProgress(),
                emitContractProblems(),
                emitEntriesStatus()
            )
            jobs.awaitAll()
            _isTabDataLoaded.emit(true)
        }
    }

    private fun emitWtDoneParts() = viewModelScope.async {
        _wtDoneParts.emit(progressApi.getProgressTripleForSvorWorkTypes(contractId).await())
    }
    private fun emitPicketsData() = viewModelScope.async {
        _picketsData.emit(analyticPageApi.getPicketProgress(contractId).await())
    }
    private fun emitPrepayment() = viewModelScope.async {
        _prepayment.emit(analyticPageApi.getContractTotalPrepayment(contractId).await())
    }
    private fun emitTotalProgress() = viewModelScope.async {
        _totalProgress.emit(analyticPageApi.getContractTotalProgress(contractId).await())
    }
    private fun emitContractProblems() = viewModelScope.async {
        _contractProblems.emit(analyticPageApi.getContractErrorStatus(contractId).await())
    }
    private fun emitEntriesStatus() = viewModelScope.async {
        _entriesStatus.emit(analyticPageApi.getSvorEntriesDashBoardStatus(contractId).await())
    }

    override fun onEvent(uiEvent: RoadsUiEvent) {
        TODO("Not yet implemented")
    }
}
