package ru.novasoft.roads.compose_client.core.ui.menu

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp

/**
 * Шаблон страницы со списком меню
 *
 * @param pageName текстовое название страницы, которое будет в шапке
 * @param pageHeader верхняя панель управления
 * @param pageContent контент страницы
 */
@Composable
fun MenuPageTemplate(
    pageName: String = "Не определено название",
    pageHeader: @Composable (String) -> Unit,
    pageContent: @Composable () -> Unit,
) {

    Scaffold(
        topBar = {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(50.dp)
                    .background(color = MaterialTheme.colorScheme.secondaryContainer),
                verticalAlignment = Alignment.CenterVertically
            ) {
                pageHeader(pageName)
            }
        }
    ) { paddingValues ->
        Box(
            Modifier
                .fillMaxSize()
                .padding(paddingValues)
                .background(MaterialTheme.colorScheme.surfaceContainer),
            Alignment.TopCenter
        ) { pageContent() }
    }

}