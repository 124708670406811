package novasoft.roads.dto.company_team.person

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import novasoft.roads.dto.company_team.RightLevel

// TODO: ROADS-97
@Serializable
data class PersonInfoDto(
    @SerialName("p")
    val personId: Int = -1,
    @SerialName("n")
    val personName: String = "",
    @SerialName("m")
    val moduleLevels: Map<String, RightLevel> = mapOf(),
    @SerialName("i")
    val companyId: String = "",
    @SerialName("c")
    val companyName: String = "",
    @SerialName("t")
    val jwtToken: String = "",
    @SerialName("e")
    val expire: Long = -1L
)