package novasoft.roads.dto.meter_progress

import kotlinx.serialization.Serializable

/**
 * Эта dto не может без такого констроктора
 */
@Serializable
data class ProgressDto(
    // TODO: ROADS-97
    var planned: Long,
    var done: Long,
    var reported: Long,
    var gplanned: Long,
    val gplanPart: Double,
    val donePart: Double,
    val passedPart: Double
)