package ru.novasoft.roads.compose_client.core.model.mapping

import novasoft.roads.dto.client_common.ContractDto
import ru.novasoft.roads.compose_client.core.model.model.ContractModel

fun toContractDto(model: ContractModel) = ContractDto(
    model.name,
    model.customerName,
    model.contractNumber,
    model.type,
    model.startDate,
    model.archive,
    model.contractId,
    model.worth,
    model.range?.let { toRoadRangeDto(it) }
)

fun toContractModel(dto: ContractDto) = ContractModel(
    dto.name,
    dto.customerName,
    dto.contractNumber,
    dto.type,
    dto.startDate,
    dto.archive,
    dto.contractId,
    dto.worth,
    dto.range?.let { toRoadRangeModel(it) }
)