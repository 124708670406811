@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package novasoft_roads.core.designsystem.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainFont0 {
  public val GolosText_Black: FontResource by 
      lazy { init_GolosText_Black() }

  public val GolosText_Bold: FontResource by 
      lazy { init_GolosText_Bold() }

  public val GolosText_ExtraBold: FontResource by 
      lazy { init_GolosText_ExtraBold() }

  public val GolosText_Medium: FontResource by 
      lazy { init_GolosText_Medium() }

  public val GolosText_Regular: FontResource by 
      lazy { init_GolosText_Regular() }

  public val GolosText_SemiBold: FontResource by 
      lazy { init_GolosText_SemiBold() }
}

@InternalResourceApi
internal fun _collectCommonMainFont0Resources(map: MutableMap<String, FontResource>) {
  map.put("GolosText_Black", CommonMainFont0.GolosText_Black)
  map.put("GolosText_Bold", CommonMainFont0.GolosText_Bold)
  map.put("GolosText_ExtraBold", CommonMainFont0.GolosText_ExtraBold)
  map.put("GolosText_Medium", CommonMainFont0.GolosText_Medium)
  map.put("GolosText_Regular", CommonMainFont0.GolosText_Regular)
  map.put("GolosText_SemiBold", CommonMainFont0.GolosText_SemiBold)
}

internal val Res.font.GolosText_Black: FontResource
  get() = CommonMainFont0.GolosText_Black

private fun init_GolosText_Black(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:GolosText_Black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/novasoft_roads.core.designsystem.generated.resources/font/GolosText_Black.ttf", -1, -1),
    )
)

internal val Res.font.GolosText_Bold: FontResource
  get() = CommonMainFont0.GolosText_Bold

private fun init_GolosText_Bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:GolosText_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/novasoft_roads.core.designsystem.generated.resources/font/GolosText_Bold.ttf", -1, -1),
    )
)

internal val Res.font.GolosText_ExtraBold: FontResource
  get() = CommonMainFont0.GolosText_ExtraBold

private fun init_GolosText_ExtraBold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:GolosText_ExtraBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/novasoft_roads.core.designsystem.generated.resources/font/GolosText_ExtraBold.ttf", -1, -1),
    )
)

internal val Res.font.GolosText_Medium: FontResource
  get() = CommonMainFont0.GolosText_Medium

private fun init_GolosText_Medium(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:GolosText_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/novasoft_roads.core.designsystem.generated.resources/font/GolosText_Medium.ttf", -1, -1),
    )
)

internal val Res.font.GolosText_Regular: FontResource
  get() = CommonMainFont0.GolosText_Regular

private fun init_GolosText_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:GolosText_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/novasoft_roads.core.designsystem.generated.resources/font/GolosText_Regular.ttf", -1, -1),
    )
)

internal val Res.font.GolosText_SemiBold: FontResource
  get() = CommonMainFont0.GolosText_SemiBold

private fun init_GolosText_SemiBold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:GolosText_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/novasoft_roads.core.designsystem.generated.resources/font/GolosText_SemiBold.ttf", -1, -1),
    )
)
