package novasoft.roads.dto.svor

import kotlinx.serialization.Serializable

@Serializable
data class SvorPlanDiffStatusDto(
    var mainStatus: String = "",
    var details: String = "",
    var isOk: Boolean = false
)
