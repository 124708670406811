package novasoft.roads.dto.resource

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import novasoft.roads.dto.client_common.ResourceTypeDto
import novasoft.roads.dto.entities_bundle.MeasuringUnitDto
import novasoft.roads.dto.entities_bundle.VariableDto

@Serializable
data class ResourceDto(
    @SerialName("i")
    val resourceId: Long = -1,
    @SerialName("n")
    val name: String = "",
    @SerialName("t")
    val type: ResourceTypeDto,
    @SerialName("tv")
    val targetVariable: VariableDto = VariableDto(),
    @SerialName("u")
    val prefUnit: MeasuringUnitDto?,
    @SerialName("f")
    val formula: String,
    @SerialName("c")
    val oneCName: String = "",
    @SerialName("r")
    val rules: MutableSet<ResourceRuleDto> = mutableSetOf()
)